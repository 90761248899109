import * as React from 'react';
import { useState } from 'react';
import { CreateBase, Form, Toolbar } from 'react-admin';
import { Card, CardContent, Box, Avatar } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { MeetInputs as Inputs } from './MeetInputs';
import { usePopup } from '../design/PopupContext'; 

const MeetCreate = ({initialRequest = null}) => {
    const { closePopup } = usePopup();
    const [currentRequest, setCurrentRequest] = useState(null);

    const handleRequestChange = (data) => {
        setCurrentRequest(data);
    };

    return (
    <CreateBase
        redirect={false}
        resource="meetings"
        mutationMode="optimistic"
        transform={(data) => {
            closePopup();

            return {
            ...data,
            updated_at: new Date(),
            contact_name: initialRequest && !currentRequest ? initialRequest.name : currentRequest.name,
            contact_phone: initialRequest && !currentRequest  ? initialRequest.phone : currentRequest.phone,
            manager_name: initialRequest && !currentRequest  ? initialRequest.manager_name : currentRequest.manager_name,
            manager_account_id: initialRequest && !currentRequest  ? initialRequest.manager_account_id : currentRequest.manager_account_id,
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            }
        }}
    >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <Inputs onRequestChange={handleRequestChange} initialRequest={initialRequest} />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    </CreateBase>
    );
}

export default MeetCreate;
