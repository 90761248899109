import * as React from 'react';
import { useState } from 'react';
import {
    EditBase,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
} from 'react-admin';
import { Card, CardContent, Box, Button } from '@mui/material';
import { parseISO, formatISO, setHours, setMinutes, setSeconds } from 'date-fns';
import { Avatar } from '../contacts/Avatar';
import { MeetInputs as Inputs } from './MeetInputs';
import '../crm.css';

const MeetEdit = () => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const [newTime, setNewTime] = useState(null);

    const handleDelete = async (id) => {
        // Fetch the existing record to get the data before delete
        const existingRecord = await dataProvider.getOne('meetings', { id });

        // Modify the data or perform additional actions
        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: 'meetings'
        };

        // Use dataProvider to send the delete request with modified data
        await dataProvider.create('delete', { data: modifiedData });

        // You can also perform additional actions after delete if needed
        //console.log(`Deleted record with ID: ${id}`);
        redirect(`/meetings`);
    };

    return (
        <EditBase redirect="list" 
             mutationMode="optimistic"
             transform={async(data) => {

                const existingRecord = await dataProvider.getOne('meetings', { id: data.id });

                // Extract date from existingRecord and time from data.date_time
                const existingDate = new Date(existingRecord.data.date_time).toISOString().split('T')[0]; // Get date part (YYYY-MM-DD)
                const newTime = new Date(data.date_time).toISOString().split('T')[1]; // Get time part (HH:mm:ss.sssZ)
  
                // Combine existing date with new time
                const new_date_time = `${existingDate}T${newTime}`;
                
                return {
                ...data,
                date_time: new_date_time,
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                }
            }}
        >
            <EditContent onDelete={handleDelete} />
        </EditBase>
    );
}

const EditContent = ({ onDelete }) => {
    const { isLoading, record } = useEditContext();

    if (isLoading || !record) return null;

    return (
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <Inputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <CustomToolbar onDelete={() => onDelete(record.id)}/>
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};

const CustomToolbar = ({ onDelete }) => {
    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            {localStorage.getItem('role_id') <= 2 ? <Button style={{ color: 'red' }} onClick={onDelete} >Удалить</Button> : null}
        </Toolbar>
    );
};

export default MeetEdit;