import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList,
    CreateBase, Form, Toolbar 
} from 'react-admin';
import { Dialog, Grid,  Card, CardContent, Box, Avatar } from '@mui/material';
import { RequestInputs } from './RequestInputs';
import { stageChoices } from './stages';
import { typeChoices } from './types';
import { Deal } from '../types';
import { Link, useLocation } from 'react-router-dom';

const RequestCreate = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const realtyId = queryParams.get('realty_id');
    const realtyTitle = queryParams.get('realty_title') && decodeURIComponent(queryParams.get('realty_title'));

    const [currentRealtyId, setCurrentRealtyId] = useState(realtyId);
    const handleRealtyChange = (event) => {
        if(event.target){
            setCurrentRealtyId(event.target.value);
        }else{
            setCurrentRealtyId(null);
        }
    };

    const [currentManager, setCurrentManager] = useState();
    const handleManagerChange = (value) => {
        setCurrentManager(value);
    };

    return (
        <CreateBase
        redirect="edit"
        mutationMode="optimistic"
        transform={(data) => ({
            ...data,
            created_at: new Date(),
            updated_at: new Date(),
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            manager_name: currentManager.first_name + " " + currentManager.last_name,
            manager_account_id: currentManager.id,
            realty_title: realtyTitle,
            realty_id: currentRealtyId
        })}
        >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <RequestInputs onManagerChange={handleManagerChange} onRealtyChange={handleRealtyChange} realtyTitle={realtyTitle} realtyId={realtyId} />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
        </CreateBase>
    );
};

export default RequestCreate;
