import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    useEditContext,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { endpoint } from '../App';
import { ColorField, ColorInput } from 'react-admin-color-picker';

export const WebBlockInputs = () => {
    const navigate = useNavigate();
    const editContext = useEditContext();

    return (<Box flex="1" mt={-1}>

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
            <h3>Блок сайта</h3>
		</Box>

        <TextInput label="Название блока (для конструктора)" source="name" fullWidth validate={required()} />

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Заголовок" source="title" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <ImageInput source="file" label={editContext.record && editContext.record.file_path ? "Загрузите новое изображение, если хотите поменять" : "Изображение"} accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>

                {editContext.record && editContext.record.file_path ? 
                    <Box display="flex" flexDirection="column">
                        <h5>Текущее изображение:</h5>
                        <img src={`${endpoint}/${editContext.record.file_path}`} style={{width: '20rem'}} />
                    </Box> 
                : null}
            </Grid>
        </Grid>

        <TextInput label="Текст" source="text" fullWidth multiline />

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <ColorInput label="Цвет фона" source="fone_color" picker="Sketch" validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <ColorInput label="Цвет текста" source="text_color" picker="Sketch" validate={required()} />
            </Grid>
        </Grid>

    </Box>);
};
