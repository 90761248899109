import React, { useEffect, useState } from 'react';
import { Box, CardMedia, IconButton } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Gallery = ({ images, height, maxWidth }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Automatic image switch every 5 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // 5 seconds

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [images.length]);

    // Manual navigation functions
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleBack = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <Box position="relative" sx={maxWidth ? { maxWidth: maxWidth } : {}}>
            <SwipeableViews index={currentIndex} onChangeIndex={setCurrentIndex} enableMouseEvents>
                {images.map((img, index) => (
                    <CardMedia
                        key={index}
                        component="img"
                        height={height}
                        image={img.imgurl}
                        sx={{objectFit:"contain"}}
                        alt={`Image ${index + 1}`}
                    />
                ))}
            </SwipeableViews>

            {/* Navigation Arrows */}
            {images.length > 1 && (
                <>
                    <IconButton
                        onClick={handleBack}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '5px',
                            color: 'rgba(0, 0, 0, 0.5)',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <ArrowBackIosIcon  sx={{ transform: 'translateX(3px)' }} />
                    </IconButton>
                    <IconButton
                        onClick={handleNext}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '5px',
                            color: 'rgba(0, 0, 0, 0.5)',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </>
            )}

            {/* Dots Indicator */}
            <Box
                position="absolute"
                bottom="10px"
                left="50%"
                display="flex"
                justifyContent="center"
                gap="8px"
                sx={{ transform: 'translateX(-50%)' }}
            >
                {images.map((_, index) => (
                    <Box
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: currentIndex === index ? 'white' : 'rgba(255, 255, 255, 0.5)',
                            cursor: 'pointer',
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default Gallery;
