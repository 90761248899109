import * as React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {
    useRecordContext,
    useListContext,
    useCreate,
    useUpdate,
    useNotify,
    useGetIdentity,
    Identifier,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    CreateBase,
    SaveButton,
    SimpleForm,
    useCreateContext,
    Toolbar,
    TextInput,
    SelectInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    useRedirect,
    useRefresh,
    NumberInput,
    DateInput,
} from 'react-admin';
import { Box, TextField, Button, Grid, Typography  } from '@mui/material';
import { StatusSelector } from '../misc/StatusSelector';
import {useFormContext} from "react-hook-form";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

export const FinancePlanFillForm = () => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const { refetch } = useListContext();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const ref = useRef();

    if (!identity) return null;

    return (
        <Box>
            <CreateBase resource="financePlan"
                redirect={false}
                mutationMode="optimistic"
                transform={(data) => {

                    // Calculate the middle of the selected month
                    const selectedMonthMoment = moment(data.selectedMonth);
                    const middleOfMonth = selectedMonthMoment.date(15).format('YYYY-MM-DD'); // Всегда должно быть 15 число, середина месяца
                   
                    return ({
                        ...data,
                        ap_id: localStorage.getItem('id'),
                        ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                        month_year: middleOfMonth,  // Add the middle of the month in ISO format
                    });
                }}
                mutationOptions={{
                    onSuccess: () => {
                        try{
                           //refetch();
                           notify('Информация о плане сотрудника на месяц обновлена', { type: 'info', undoable: false });
                           ref.current.resetForm();
                        }catch(err){console.log(err)}
                    }}
                }
            > 
            <Form>
            <FinancePlanForm 
                ref={ref}
            />
            </Form>
            </CreateBase>
        </Box>
    );
};

const FinancePlanForm = forwardRef(({  }, ref) => {
    const { reset, setValue, watch } = useFormContext();
    const [selectedMonth, setSelectedMonth] = useState(new Date());

    useEffect(() => {
        setValue('month_year', selectedMonth);
    }, [selectedMonth, setValue]);

    useImperativeHandle(ref, () => ({
        resetForm() {
            // Reset specific form fields
            //setValue('comment', '');
            //setValue('file', '');
            //setValue('date_time', '');
        }
    })); 

        return (
            <Box mb={3}>
                <h3>План на месяц, ₽ (внесение/изменение информации)</h3>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <ReferenceInput source="manager_account_id" reference="sales">
                            <AutocompleteInput label="Менеджер" filterToQuery={search => ({ "q": search })} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <NumberInput label="План на месяц, руб." source="income_plan" fullWidth validate={required()}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
                            <DatePicker
                                views={['year', 'month']}
                                label="Месяц и год"
                                value={selectedMonth}
                                onChange={(newValue) => {console.log(newValue); setSelectedMonth(moment(newValue));}}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                sx={{ marginRight: '1rem' }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Box display="flex" mb={1} >
                    <SaveButton 
                        label="Внести информацию"
                        icon={false}
                        redirect={false} 
                        submitOnEnter={false}
                    />
                </Box>
                <div>* При повторном внесении информации в тот же месяц по менеджеру - она перезаписывается полностью, учитывайте это.</div>     
            </Box>
        );

    return null;
});