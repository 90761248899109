export const statuses = [
    {id: 'scheduled', name: 'Встреча запланирована'},
    {id: 'done', name: 'Встреча проведена'},
    {id: 'cancelled', name: 'Встреча отменена/перенесена'},
    {id: 'sold', name: 'Продажа'}
];

export const getStatusBgColor = (status) => {
    switch (status) {
        case 'scheduled':
            return '#313131';
        case 'done':
            return '#10793F';
        case 'cancelled':
            return '#ff3838';
        case 'sold':
            return '#ffa200';
        default:
            return '#F1F1F1';
    }
};