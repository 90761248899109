import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    List as RaList,
    Loading,
    useListContext,
    useGetIdentity,
    NumberInput,
    DateTimeInput,
    SearchInput,
    Pagination,
    TextInput, 
    TopToolbar,
    SelectInput,
    AutocompleteArrayInput,
    useRedirect,
    required
} from 'react-admin';
import { Box, Grid, Card, CardContent, Typography, InputAdornment, Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import Gallery from './Gallery';
import { useForm, FormProvider } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { categories, nedvigimostTypes, sources, cities } from './selectables';

const FilterForm = () => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        filterValues,
        setFilters
    } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });

    const onSubmit = (values) => {
        if (values.city) {
            values.city = values.city.join('|');
        }
        if (values.category_id) {
            values.category_id = values.category_id.join(',');
        }
        if (values.nedvigimost_type) {
            values.nedvigimost_type = values.nedvigimost_type.join(',');
        }
        if (values.source) {
            values.source = values.source.join(',');
        }

        if (Object.keys(values).length > 0) {
            setFilters(values);
        }
    };

    const resetFilter = () => {
        //form.reset(filterValues);
        setFilters({});
        form.reset({});
    };

    return (
        <Box >
        <FormProvider {...form}>
        <Typography variant="body2" color='textSecondary' textAlign={isMobile ? "start" : "center"} sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Отображаются объявления которые были опубликованы на площадках <strong>не раньше чем 30 минут назад</strong></Typography>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box display="flex" flex="1" flexWrap="wrap" gap="0.5rem" alignItems={isMobile ? "start" : "center"} mt={.5} flexDirection={isMobile ? "column" : "row"}>
                    <Box mr={1}>
                        <AutocompleteArrayInput
                            source="category_id"
                            label="Категория"
                            choices={categories}
                            helperText={false}
                        />
                    </Box>
                    <Box mr={1}>
                        <AutocompleteArrayInput
                            source="nedvigimost_type"
                            label="Тип объявления"
                            choices={nedvigimostTypes}
                            helperText={false}
                        />
                    </Box>
                   <Box mr={1} sx={{ minWidth: '12rem' }} >
                        <AutocompleteArrayInput
                            source="city"
                            label="Город или область"
                            choices={cities.map(city => ({ id: city, name: city }))}
                            helperText={false}
                        />
                    </Box>
                    <Box mr={1}>
                        <TextInput source="metro" label="Метро или район"  resettable helperText="Только точное название"/>
                    </Box>
                    <Box mr={1}>
                        <AutocompleteArrayInput
                            source="source"
                            label="Источник"
                            choices={sources}
                            helperText={false}
                        />
                    </Box>
                    <Box mr={1}>
                        <SelectInput
                            source="limit"
                            label="Лимит отображения"
                            choices={[
                                { id: 50, name: '50 шт.' },
                                { id: 100, name: '100 шт.' },
                                { id: 250, name: '250 шт.' },
                            ]}
                            defaultValue={50}
                            validate={required()} 
                            helperText={false}
                        />
                    </Box>
                </Box>
                <Box display="flex" flex="1" flexWrap="wrap" gap="0.5rem" mt={1} alignItems={isMobile ? "start" : "center"} flexDirection={isMobile ? "column" : "row"}>
                    <Box mr={1}>
                        <TextInput
                            resettable
                            helperText={false}
                            source="q"
                            label="Ключевые слова"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <SearchIcon color="disabled" />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <Box mr={1}>
                        <NumberInput source="price1" label="Цена от, руб."  helperText={false}/>
                    </Box>
                    <Box mr={1}>
                        <NumberInput source="price2" label="Цена до, руб."  helperText={false}/>
                    </Box>
                    <Box mr={1}>
                        <DateTimeInput source="date1" label="Время от"  helperText={false}/>
                    </Box>
                    <Box mr={1}>
                        <DateTimeInput source="date2" label="Время до" helperText={false}/>
                    </Box>
                    <Box mr={1}>
                        <SelectInput
                            source="person_type"
                            label="Тип аккаунта"
                            choices={[
                                { id: '1', name: 'Частное лицо' },
                                { id: '2', name: 'Агентство' },
                                { id: '3', name: 'Собственник' },
                            ]}
                            helperText={false}
                        />
                    </Box>
                    <Box mr={1}>
                        <SelectInput
                            source="param_1957"
                            label="Вид объекта"
                            choices={[
                                { id: "Вторичка", name: "Вторичка" },
                                { id: "Новостройка", name: "Новостройка" },
                            ]}
                            helperText={false}
                        />
                    </Box>
                    <Box mr={1}>
                        <NumberInput source="param_1960" label="Площадь от, м2"  helperText={false}/>
                    </Box>
                    <Box mr={1}>
                        <NumberInput source="param_1979" label="Площадь до, м2"  helperText={false}/>
                    </Box>
                    {/*<Box mr={1} sx={{ width: '20rem' }}>
                        <SelectInput
                            source="is_actual"
                            label="Актуальность (по версии парсера)"
                            choices={[
                                { id: '11', name: 'Актуально (недавно опубликовано)' },
                                { id: '1', name: 'Актуально (проверено)' },
                                { id: '0', name: 'Не актуально' },
                            ]}
                            helperText={false}
                        />
                    </Box>*/}
                </Box>
                <Box display="flex" flex="1" justifyContent="space-between" alignItems={isMobile ? "start" : "center"} mt={1} flexDirection={isMobile ? "column" : "row"}>
                    <Link to={`/realtyListingsMap`}>
                        <Button variant="outlined" color="primary">
                            Объявления на карте
                        </Button>
                    </Link>
                    <Typography variant="body2" color='textSecondary' textAlign={isMobile ? "start" : "center"}  sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Парсер принимает запросы 1 раз в 6 секунд, Ваш поиск в порядке общей очереди.<br/> <strong>Пожалуйста, ищите то, что необходимо, и будьте терпеливы.</strong></Typography>
                    <Box mr={1} >
                        <Button variant="contained" color="primary" type="submit">
                            Поиск
                        </Button>
                    </Box>
                </Box>
            </form>
        </FormProvider>
        </Box>
    );
};

const ListActions = () => (
    <TopToolbar sx={{ width: "100%" }}>
        <Card sx={{ width: "100%" }}>
            <CardContent  sx={{ width: "100%" }}>
                <FilterForm />
            </CardContent>
        </Card>
    </TopToolbar>
);

const ListContent = () => {
    const { data, isLoading, isFetching } = useListContext();

    if (!data || isLoading || isFetching) {
        return <Loading />;
    }

    return (
        <Box padding={2}>
            <Grid container spacing={1}>
                {data.map((obj) => (
                    <Grid item xs={6} sm={6} md={2} key={obj.id}>
                        <Card sx={{ maxWidth: 250 }}>
                            <Gallery images={obj.images} height="140" />
                            <Link to={`/realtyListings/${obj.id}/show`} style={{ textDecoration: 'none' }} target="_blank" >
                                <CardContent sx={{ minHeight: 200 }}>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitLineClamp: 2,
                                            height: '3rem'
                                        }}
                                    >
                                        {obj.title}
                                    </Typography>
                                    <Typography variant="body2"  color="textSecondary">
                                        {new Date(obj.time).toLocaleString('RU-ru')}
                                    </Typography>
                                    <Typography variant="body2" color="textPrimary">
                                        {obj.price} {obj.price_metric}, {obj.source}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitLineClamp: 5,
                                            height: '6rem'
                                        }}
                                    >
                                        {obj.description}
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const RealtyListingsList = () => {
    const { identity } = useGetIdentity();

    if (!identity) return null;

    return (
        <RaList
            sx={{ marginTop: '1.5rem' }}
            empty={false}
            pagination={false}
            actions={<ListActions />}
            queryOptions={{ refetchOnWindowFocus: false }}
            storeKey={false}
            filterDefaultValues={{ limit: 50 }}
        >
            <ListContent />
        </RaList>
    );
};

export default RealtyListingsList;