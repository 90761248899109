import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    EditBase,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
    ReferenceManyField,
    useRecordContext
} from 'react-admin';
import { Card, CardContent, Box, Button, Tab, Tabs, Divider, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { Avatar } from '../contacts/Avatar';
import { RequestInputs } from './RequestInputs';
import '../crm.css';
import { HistoryIterator } from '../requestsHistory/HistoryIterator';
import { NewHistoryComment } from '../requestsHistory/NewHistoryComment';
import { CallsWithContact } from '../calls/CallsWithContact';
import { WazzupChat } from '../calls/WazzupChat';
import RealtyListingShow from '../realtyListings/RealtyListingShow';
import { useNavigate } from 'react-router-dom';

const RequestEdit = () => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const navigate = useNavigate();
    
    const [currentContact, setCurrentContact] = useState();
    const handleContactChange = (value) => {
        setCurrentContact(value);
    };

    const [currentManager, setCurrentManager] = useState();
    const handleManagerChange = (value) => {
        setCurrentManager(value);
    };

    const [leadScreenWebsites, setLeadScreenWebsites] = useState([]);

    const handleDelete = async (id) => {
        const existingRecord = await dataProvider.getOne('requests', { id });

        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: 'requests'
        };

        await dataProvider.create('delete', { data: modifiedData });

        redirect(`/requests`);
    };

    const fetchLSWebsites = async () => {
        const LSWebsites = await dataProvider.getList('leadScreenWebsites', {
            pagination: { page: 1, perPage: 500 },
            sort: { field: 'updated_at', order: 'ASC' },
            filter: {},
        });
        setLeadScreenWebsites(LSWebsites.data);
    }

    useEffect(() => {
        fetchLSWebsites();
    }, []);

    return (
        <Box display="flex" flexDirection="column" >
        <EditBase redirect={() => { navigate(-1); return false;}}
             mutationMode="optimistic"
             transform={(data) => ({
                ...data,
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                manager_name: `${currentManager.first_name} ${currentManager.last_name}`,
                manager_account_id: currentManager.id,
            })}
        >
            <RequestEditContent onDelete={handleDelete} handleManagerChange={handleManagerChange} leadScreenWebsites={leadScreenWebsites} />
        </EditBase>

        
        </Box>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (children)}
      </div>
    );
}


const RequestEditContent = ({ onDelete, handleManagerChange, leadScreenWebsites }) => {
    const { isLoading, record } = useEditContext();
    const [value, setValue] = useState(0);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isLoading || !record) return null;

    if(isMobile){
        return (
            <Box display="flex" flexDirection="column" sx={{ overflowY: 'hidden', marginTop: '1rem'}}>
                <Box flex="1" display="flex" flexDirection="column">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Заявка (лид)" />
                        <Tab label="Комментарии и задачи" />
                        <Tab label="Объявление" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                <Box flex="1">
                    <Form>
                        <Card>
                            <CardContent>
                                <Box>
                                    <Box display="flex">
                                        <RequestInputs onManagerChange={handleManagerChange} />
                                    </Box>
                                </Box>
                            </CardContent>
                            <CustomToolbar onDelete={() => onDelete(record.id)}/>
                        </Card>
                    </Form>
                </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                <Box flex="1" sx={{display: 'flex', flexDirection: 'column-reverse', paddingLeft: '1rem', height: '85vh', }}>
                        <ReferenceManyField
                         target="request_id"
                         reference="requestsHistoryComments"
                         filter={{request_id: record.request_id, contact_id: record.contact_id}}
                         perPage={10000}
                         sort={{ field: 'created_at', order: 'DESC' }}
                         sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse',}}
                        >
                            <NewHistoryComment reference="requests" />
                            <HistoryIterator reference="requests" />
                        </ReferenceManyField>
                </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    {record.realty_id ? 
                    <RealtyListingShow card_realty_id={record.realty_id} />
                    :
                    <Box ml={2} mt={2} flex="1">
                        <Typography>
                            Объявление пока не прикреплено к заявке.
                        </Typography>
                    </Box>
                    }
                </CustomTabPanel>
                </Box>
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="row" sx={{ overflowY: 'hidden', marginTop: '1rem'}}>
            <Box flex="1">
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <RequestInputs onManagerChange={handleManagerChange} />
                                </Box>
                            </Box>
                        </CardContent>
                        <CustomToolbar onDelete={() => onDelete(record.id)}/>
                    </Card>
                </Form>
            </Box>
            <Box flex="2" display="flex" flexDirection="column">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Комментарии и задачи" />
                    <Tab label="Объявление" />
                    {leadScreenWebsites.map((site, index) => (
                            <Tab label={site.name} key={index} />
                        ))}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
            <Box flex="1" sx={{display: 'flex', flexDirection: 'column-reverse', paddingLeft: '1rem', height: '85vh', }}>
                    <ReferenceManyField
                     target="request_id"
                     reference="requestsHistoryComments"
                     filter={{request_id: record.request_id, contact_id: record.contact_id}}
                     perPage={10000}
                     sort={{ field: 'created_at', order: 'DESC' }}
                     sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse',}}
                    >
                        <NewHistoryComment reference="requests" />
                        <HistoryIterator reference="requests" />
                    </ReferenceManyField>
            </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {record.realty_id ? 
                <RealtyListingShow card_realty_id={record.realty_id} />
                :
                <Box ml={2} mt={2} flex="1">
                    <Typography>
                        Объявление пока не прикреплено к заявке.
                    </Typography>
                </Box>
                }
            </CustomTabPanel>
            {leadScreenWebsites.map((site, index) => (
                    <CustomTabPanel value={value} index={index + 2} key={index}>
                        <iframe 
                            src={site.url} 
                            title={site.name} 
                            style={{ width: '100%', height: '80vh', border: 'none' }} 
                        />
                       <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 1 }}>
                            <Link to={site.url} target="_blank">
                                <Button variant="contained" color="primary">
                                    Открыть {site.name} в новой вкладке
                                </Button>
                            </Link>
                        </Box>
                    </CustomTabPanel>
                ))}
            </Box>
        </Box>
    );
};

const CustomToolbar = ({ onDelete }) => {
    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            {localStorage.getItem('role_id') < 2 ? <Button style={{ color: 'red' }} onClick={onDelete} >Удалить</Button> : null}
        </Toolbar>
    );
};

export default RequestEdit;