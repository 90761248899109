import * as React from 'react';
import {
    ShowBase,
    useShowContext,
    useDataProvider,
    Loading,
    ReferenceInput,
    AutocompleteInput,
    required,
    Form
} from 'react-admin';
import { Box, Card, CardContent, Typography, Button, useMediaQuery } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Gallery from './Gallery';

const RealtyListingShow = ({ card_realty_id }) => {
    const { realty_id } = useParams();

    // If opened from request or deal card
    if (card_realty_id) {
        return (
            <ShowBase resource="realtyListings" id={card_realty_id} queryOptions={{ refetchOnWindowFocus: false }}>
                <RealtyShowContent />
            </ShowBase>
        );
    }

    // If opened on page
    return (
        <ShowBase resource="realtyListings" id={realty_id} queryOptions={{ refetchOnWindowFocus: false }}>
            <RealtyShowContent />
        </ShowBase>
    );
};

const RealtyShowContent = () => {
    const { record, isLoading } = useShowContext();
    const { realty_id } = useParams();
    const dataProvider = useDataProvider();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [selectedRequestId, setSelectedRequestId] = React.useState(); // Store selected request ID

    if (isLoading || !record) return <Loading />;

    const handleRequestChange = (value) => {
        setSelectedRequestId(value); // Update the selected request ID
    };

    const handleRequestUpdate = async () => {
        if (!selectedRequestId) {
            alert("Пожалуйста, выберите заявку перед тем как присвоить ей объявление!");
            return;
        }
        try {
            // Update the selected request with realty_id and realty_title
            await dataProvider.update('requests', {
                id: selectedRequestId,
                data: {
                    ap_id: localStorage.getItem('id'),
                    ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                    realty_id: record.id.toString(),
                    realty_title: record.title,
                },
            });
            // Navigate to the updated request's page
            navigate(`/requests/${selectedRequestId}`);
        } catch (error) {
            console.error("Error updating request:", error);
            alert("Ошибка при обновлении заявки. Пожалуйста, попробуйте еще раз.");
        }
    };

    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>
                        <Box ml={2} flex="1">
                            <Typography variant="h5">
                                {record.title}
                            </Typography>
                        </Box>

                        <Box>
                            <Gallery images={record.images} height={isMobile ? "300" : "450"} maxWidth={isMobile ? "100%" : "40rem"} />

                            <Box display="flex" alignItems="center" flex="1" mt={1} flexWrap="wrap" >
                                {record.request_id ? (
                                     <Box display="flex" alignItems="center" flex="1" mt={1}>
                                     <Link to={record.url} target="_blank">
                                         <Button variant="outlined" color="primary" sx={{ marginRight: '1rem' }}>
                                             Открыть на {record.source}
                                         </Button>
                                     </Link>

                                     {!realty_id && <Link to={`/realtyListings/${record.id}/show`} target="_blank">
                                        <Button variant="contained" color="primary" sx={{ marginRight: '1rem' }}>
                                            Открыть в новой вкладке CRM
                                        </Button>
                                    </Link>}

                                    {realty_id && <Link to={`/requests/${record.request_id}`} target="_blank">
                                        <Button variant="contained" color="warning">
                                            С этим объявлением уже есть заявка (лид) в системе: {record.request_manager_name} - {record.request_name}
                                        </Button>
                                     </Link>}

                                     </Box>
                                ) : (
                                    <Box flex="1" >

                                     <Box display="flex" alignItems="center" flex="1" mt={1} mb={1} >
                                     <Link to={record.url} target="_blank">
                                         <Button variant="outlined" color="primary" sx={{ marginRight: '1rem' }}>
                                             Открыть на {record.source}
                                         </Button>
                                     </Link>

                                     <Link to={createRequestUrl(record.id, record.title)} target="_blank">
                                                <Button variant="contained" color="primary">
                                                    Создать новую заявку (лид) с этим объявлением
                                                </Button>
                                     </Link>

                                     </Box>
                                        
                                     <Card sx={{ marginBottom: '1rem', width: '100%' }}>
                                        <CardContent>
                                        <Form>
                                        <ReferenceInput
                                            source="request_id"
                                            reference="requests"
                                            filter={{ sales_id_for_requests: localStorage.getItem('id') }}
                                        >
                                            <AutocompleteInput
                                                label="Заявка (лид) (введите часть имени или номера и подождите 5 секунд)"
                                                filterToQuery={(search) => ({ q: search })}
                                                optionText={(choice) => `${choice.name} ${choice.phone} ${choice.realty_id ? choice.realty_title : ''} - ${choice.manager_name}`}
                                                onChange={handleRequestChange}
                                                fullWidth
                                                validate={required()}
                                            />
                                        </ReferenceInput>

                                        <Button variant="contained" color="primary" onClick={handleRequestUpdate}>
                                            Присвоить объявление выбранной заявке
                                        </Button>
                                        </Form>
                                        </CardContent>
                                     </Card>
                                    </Box>
                                )}
                            </Box>

                            {/* Additional fields for record details */}
                            <Box display="flex" alignItems="center" flex="1" mt={1}>
                                <Box>
                                    <Typography variant="body2">Добавлено: </Typography>
                                </Box>
                                <Box ml={1}>
                                    <Typography variant="body1">{record.time}</Typography>
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="center" flex="1" mt={1}>
                                <Box>
                                    <Typography variant="body2">Актуально (по версии парсера): </Typography>
                                </Box>
                                <Box ml={1}>
                                    <Typography variant="body1">{record.is_actual > 0 ? 'Да' : 'Нет'}</Typography>
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="center" flex="1" mt={1}>
                                <Box>
                                    <Typography variant="body2">Тип объявления: </Typography>
                                </Box>
                                <Box ml={1}>
                                    <Typography variant="body1">{record.nedvigimost_type}</Typography>
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="center" flex="1" mt={1}>
                                <Box>
                                    <Typography variant="body2">Цена:</Typography>
                                </Box>
                                <Box ml={1}>
                                    <Typography variant="body1">{record.price} {record.price_metric}</Typography>
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="center" flex="1" mt={1}>
                                <Box>
                                    <Typography variant="body2">Контактное лицо:</Typography>
                                </Box>
                                <Box ml={1}>
                                    <Typography variant="body1">{record.person} {record.contactname} {record.person_type}</Typography>
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="center" flex="1" mt={1}>
                                <Box>
                                    <Typography variant="body2">Объявлений с тем же номером телефона:</Typography>
                                </Box>
                                <Box ml={1}>
                                    <Typography variant="body1">{record.count_ads_same_phone}</Typography>
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="center" flex="1" mt={1}>
                                <Box>
                                    <Typography variant="body2">Адрес:</Typography>
                                </Box>
                                <Box ml={1}>
                                    <Link
                                        to={`https://maps.yandex.ru?text=${record.city},${record.address},${record.metro}&ll=${record.coords.lng},${record.coords.lat}`}
                                        target="_blank"
                                    >
                                        {record.city}, {record.address} {record.metro}
                                    </Link>
                                </Box>
                            </Box>

                            {record.km_do_metro > 0 && (
                                <Box display="flex" alignItems="center" flex="1" mt={1}>
                                    <Box>
                                        <Typography variant="body2">До метро:</Typography>
                                    </Box>
                                    <Box ml={1}>
                                        <Typography variant="body1">{record.km_do_metro} км</Typography>
                                    </Box>
                                </Box>
                            )}

                            <Box mt={1}>
                                {record.params && Object.entries(record.params).map(([key, value]) => (
                                    <Typography key={key} variant="body1">{`${key}: ${value}`}</Typography>
                                ))}
                                {record.params2 && convertObjectToText(record.params2)}
                            </Box>

                            <Box mt={1}>
                                {record.description}
                            </Box>

                            <Box mt={1}>
                                <iframe
                                    src={`https://yandex.ru/map-widget/v1/?ll=${record.coords.lng},${record.coords.lat}&z=12&pt=${record.coords.lng},${record.coords.lat},pm2rdm`}
                                    width={isMobile ? "100%" : "560"}
                                    height={isMobile ? "300" : "400"}
                                    frameBorder="1"
                                    allowFullScreen
                                ></iframe>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

const createRequestUrl = (realtyId, realtyTitle) => {
    const baseUrl = '/requests/create';
    const params = new URLSearchParams();
    params.append('realty_id', realtyId);
    params.append('realty_title', encodeURIComponent(realtyTitle));
    return `${baseUrl}?${params.toString()}`;
};

const convertObjectToText = (obj) => {
    return Object.entries(obj).map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
            return convertObjectToText(value);
        } else {
            return <Typography variant="body1" key={key}>{`${key}: ${value}`}</Typography>;
        }
    });
};

export default RealtyListingShow;


/*import * as React from 'react';
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    useShowContext,
    EditButton,
    Datagrid,
    DateField,
    SimpleList,
    Pagination,
    FilterLiveSearch,
    List as RaList,
    useListContext,
    SimpleListLoading,
    RecordContextProvider,
    TopToolbar,
    ExportButton,
    FunctionField,
    Loading
} from 'react-admin';
import { Box, Card, CardContent, Typography, ListItem, List, ListItemIcon, ListItemText, IconButton, Button } from '@mui/material';
import '../crm.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Gallery from './Gallery';

const RealtyListingShow = ({ card_realty_id }) => {
    const { realty_id } = useParams();

    // If opened from request or deal card
    if(card_realty_id){
        return (
            <ShowBase resource="realtyListings" id={card_realty_id}  queryOptions={{ refetchOnWindowFocus: false }}>
                <RealtyShowContent />
            </ShowBase>
        );
    }

     // If opened on page
    return (
        <ShowBase resource="realtyListings" id={realty_id}  queryOptions={{ refetchOnWindowFocus: false }}>
            <RealtyShowContent />
        </ShowBase>
    );
};

const RealtyShowContent = () => {
    const { record, isLoading } = useShowContext();
    const navigate = useNavigate();
    if (isLoading || !record) return <Loading />;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Card>
                    <CardContent>

                        <Box ml={2} flex="1">
                            <Typography variant="h5">
                                {record.title}
                            </Typography>
                        </Box>

                         <Box>
                         
                         <Gallery images={record.images} height="450" maxWidth="40rem" />

                        <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                        <Link to={record.url} target="_blank">
                            <Button variant="outlined" color="primary" sx={{ marginRight: '1rem' }}>
                                Открыть на {record.source}
                            </Button>
                        </Link>
                        </Box>

                        <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                        {record.request_id ? 
                        <Link to={`/requests/${record.request_id}`} target="_blank">
                            <Button variant="contained" color="warning">
                               С этим объявлением уже есть заявка (лид) в системе: {record.request_manager_name} - {record.request_name}
                            </Button>
                        </Link> :
                        <Box>
                        <Link to={createRequestUrl(record.id, record.title)} target="_blank">
                                <Button variant="contained" color="primary">
                                    Создать заявку (лид) с этим объявлением
                                </Button>
                        </Link>

                        <ReferenceInput source="request_id" reference="requests" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                            <AutocompleteInput 
                                isLoading={true} 
                                label="Заявка (лид) (введите часть имени или номера и подождите 5 секунд)" 
                                filterToQuery={search => ({ "q": search })}  optionText={(choice) => `${choice.name} ${choice.phone} - ${choice.manager_name}`} 
                                fullWidth 
                                onChange={handleRequestChange} 
                                validate={required()} 
                            />
                        </ReferenceInput>
                        </Box>
                        }

                        </Box>

                         <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Добавлено: </Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.time}</Typography>
                            </Box>
                         </Box>

                         <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Актуально (по версии парсера): </Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.is_actual > 0 ? 'Да' : 'Нет'}</Typography>
                            </Box>
                         </Box>

                         <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Тип объявления: </Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.nedvigimost_type}</Typography>
                            </Box>
                         </Box>

                         <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Цена:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.price} {record.price_metric}</Typography>
                            </Box>
                         </Box>

                         <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Контактное лицо:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.person} {record.contactname} {record.person_type}</Typography>
                            </Box>
                         </Box>

                         <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Объявлений с тем же номером телефона:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.count_ads_same_phone}</Typography>
                            </Box>
                         </Box>

                         <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Адрес:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Link 
                                to={`https://maps.yandex.ru?text=${record.city},${record.address},${record.metro}&ll=${record.coords.lng},${record.coords.lat}`}
                                target="_blank"
                                >
                                    {record.city}, {record.address} {record.metro}
                                </Link>
                            </Box>
                         </Box>

                         {record.km_do_metro > 0 && <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">До метро:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.km_do_metro} км</Typography>
                            </Box>
                         </Box>}

                         <Box mt={1} >
                            {record.params && Object.entries(record.params).map(([key, value]) => <Typography variant="body1">{`${key}: ${value}`}</Typography>)}
                            {record.params2 && convertObjectToText(record.params2)}
                         </Box>

                         <Box mt={1}>
                            {record.description}
                         </Box>

                         <Box mt={1}>
                         <iframe
                            src={`https://yandex.ru/map-widget/v1/?ll=${record.coords.lng},${record.coords.lat}&z=12&pt=${record.coords.lng},${record.coords.lat},pm2rdm`}
                            width="560"
                            height="400"
                            frameborder="1"
                            allowfullscreen="true"
                            ></iframe>
                         </Box>


                         </Box>

                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

const createRequestUrl = (realtyId, realtyTitle) => {
    const baseUrl = '/requests/create';
    const params = new URLSearchParams();
    params.append('realty_id', realtyId);
    params.append('realty_title', encodeURIComponent(realtyTitle));
    return `${baseUrl}?${params.toString()}`;
};

const convertObjectToText = (obj) => {
    return Object.entries(obj).map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
            return convertObjectToText(value);
        } else {
            return <Typography variant="body1">{`${key}: ${value}`}</Typography>;
        }
    });
}

export default RealtyListingShow;*/