import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SelectInput,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    useMediaQuery,
    Button,
    Card,
    CardContent
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import * as XLSX from 'xlsx';
import { CustomExportButton, shops, statuses } from './DeliveryRequestsInputs';
import axios from 'axios';
import { endpoint } from '../App';

const FilterForm = () => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        data,
        filterValues,
        setFilters
    } = useListContext();

    const form = useForm({
        defaultValues: filterValues,
    });

    const onValid = (filterData) => {
        setFilters(filterData);
    };

    let total_price = 0;
    let total_completed = 0;
    let total_orders = 0;
    if (data) {
        data.forEach(obj => {
            total_orders += 1;
            if(obj.status > 5){
                total_price += obj.price_final;
                total_completed += 1;
            }
        });
    }
    let personal_gain = parseInt(total_price * 0.02);
    let total_gain = parseInt(total_price * 0.08);

    return (
        <Box >
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onValid)}>
                <Box display="flex" flex="1" flexWrap="wrap" gap="0.5rem" alignItems={isMobile ? "start" : "center"} mt={.5} flexDirection={isMobile ? "column" : "row"}>
                    <Box mr={1}>
                        <SelectInput
                            source="shop"
                            label="Магазин"
                            choices={[
                                { id: 'lemana', name: 'Лемана ПРО' },
                                { id: 'vseinstrumenti', name: 'ВсеИнструменты.ру' },
                            ]}
                            helperText={false}
                            onChange={form.handleSubmit(onValid)}
                        />
                    </Box>

                    <Box display="flex" flex="1" flexDirection="column">
                    <Typography variant="body2">Сумма выполненных заказов:</Typography>
                    <Typography variant="body2">{total_price} руб.</Typography>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column">
                    <Typography variant="body2">Количество выполненных заказов:</Typography>
                    <Typography variant="body2">{total_completed} шт.</Typography>
                    <Typography variant="body2">Всего заказов:</Typography>
                    <Typography variant="body2">{total_orders} шт.</Typography>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column">
                    <Typography variant="body2">2%:</Typography>
                    <Typography variant="body2">{personal_gain} р.</Typography>
                    <Typography variant="body2">8%:</Typography>
                    <Typography variant="body2">{total_gain} р.</Typography>
                    </Box>
                </Box>

                {/*<Box display="flex" flex="1" justifyContent="space-between" alignItems={isMobile ? "start" : "center"} mt={1} flexDirection={isMobile ? "column" : "row"}>
                    <Box mr={1} >
                        <Button variant="contained" color="primary" type="submit">
                            Поиск
                        </Button>
                    </Box>
                </Box>*/}
            </form>
        </FormProvider>
        </Box>
    );
};

const ListActions = () => (
    <TopToolbar sx={{ width: "100%" }}>
        <Card sx={{ width: "100%" }}>
            <CardContent  sx={{ width: "100%" }}>
                <FilterForm />
            </CardContent>
        </Card>
    </TopToolbar>
);

const ListContent = () => {
    const {
        data,
        isLoading,
        onToggleItem,
        selectedIds,
        refetch
    } = useListContext();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    // Взять в работу (наш менеджер)
    const takeOrder = async (order_id) => {
      try {
          const token = localStorage.getItem('token'); // Get token from localStorage
          const response = await axios.put(
              `${endpoint}/deliveryRequests/${order_id}`,
              { 
                manager_id: localStorage.getItem('id'),
                manager_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
              }, 
              {
                  headers: {
                      Authorization: `${token}` // Set Authorization header with token
                  },
              }
          );
          console.log('Order status updated:', response.data);
          refetch();
      } catch (error) {
          console.error('Error updating order status:', error);
      }
    };

    // Взять в работу (менеджер магазина)
    const takeOrderShopManager = async (order_id) => {
      try {
          const token = localStorage.getItem('token'); // Get token from localStorage
          const response = await axios.put(
              `${endpoint}/deliveryRequests/${order_id}`,
              { 
                shop_manager_status: 'in_progress',
                shop_manager_id: localStorage.getItem('id'),
                shop_manager_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
              }, 
              {
                  headers: {
                      Authorization: `${token}` // Set Authorization header with token
                  },
              }
          );
          console.log('Order status updated:', response.data);
          refetch();
      } catch (error) {
          console.error('Error updating order status:', error);
      }
    };

    // Завершить выполнение
    const finishOrderShopManager = async (order_id) => {
      try {
          const token = localStorage.getItem('token'); // Get token from localStorage
          const response = await axios.put(
              `${endpoint}/deliveryRequests/${order_id}`,
              { 
                shop_manager_status: 'done',
                shop_manager_id: localStorage.getItem('id'),
                shop_manager_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
              }, 
              {
                  headers: {
                      Authorization: `${token}` // Set Authorization header with token
                  },
              }
          );
          console.log('Order status updated:', response.data);
          refetch();
      } catch (error) {
          console.error('Error updating order status:', error);
      }
    };

    return (
        <>
            {data.length < 1 && <Box sx={{ margin: '1rem' }}><h5>Заказы на доставку появятся здесь. Пока заказов нет.</h5></Box>}
            <List>
                {data.map(obj => (
                    <RecordContextProvider key={obj.id} value={obj}>
                        <ListItem
                            to={localStorage.getItem('role_id') <= 2 || localStorage.getItem('id') == obj.manager_account_id ? `/deliveryRequests/${obj.id}` : '/deliveryRequests'}
                        >
                            <Box display="flex" flex="1" >
                            <ListItemText
                                primary={`Заказ №${obj.id}`}
                                secondary={new Date(obj.created_at).toLocaleString("RU-ru")}
                            />
                            </Box>
                            
                            <RequestFields obj={obj} takeOrder={takeOrder} takeOrderShopManager={takeOrderShopManager} finishOrderShopManager={finishOrderShopManager} />

                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const RequestFields = ({obj, takeOrder, finishOrderShopManager, takeOrderShopManager}) => {

    // Отображение отмены заказа
    if(obj.status > 0 && obj.status <= 2){
        if(localStorage.getItem('role_id') == 16 || localStorage.getItem('role_id') == 20){
            return(
                <>
                    <Box display="flex" flex="1" >
                        <ListItemText
                            primary="Заказ отменён"
                        />
                    </Box>
                </>
            );
        }else{
            return(
                <>
                    <Box display="flex" flex="1" >
                        <ListItemText
                            primary="Отмена"
                            secondary={obj.name ? obj.name : 'внести'}
                        />
                    </Box>
                    <Box display="flex" flex="1" >
                        <ListItemText
                            primary={obj.status ? statuses.find(s => s.id == obj.status).name : 'Начать работу с заказом'}
                            secondary={obj.shop ? shops.find(s => s.id == obj.shop).name : 'внести'}
                        />
                    </Box>   
                    <Box display="flex" flex="1" >
                        <EditButton label="Детали заказа" />
                    </Box>
                    <Box display="flex" flex="1" >
                    </Box>
                </>
            );
        }
    }

    // Менеджер подтвердил заказ, теперь он должен согласовать детали, а сотрудник Леруа выполнить свою часть
    if(obj.status >= 3){
    
    if(localStorage.getItem('role_id') == 16){
    return(
        <>
            <Box display="flex" flex="1" >
                <CustomExportButton record={obj} />
            </Box>
            <Box display="flex" flex="1" >
                <Button onClick={() => takeOrderShopManager(obj.id)} variant='contained' disabled={obj.shop_manager_status}>
                    {obj.shop_manager_status ? 'В работе' : 'Взять в работу'}
                </Button>
            </Box>
            <Box display="flex" flex="1" >
                <ListItemText
                    primary={obj.shop_manager_name && obj.shop_manager_status == 'in_progress' && `Взят в работу сотрудником ${obj.shop_manager_name}`}
                />
            </Box>
            <Box display="flex" flex="1" >
                <Button onClick={() => finishOrderShopManager(obj.id)} variant='contained' disabled={obj.shop_manager_status == 'done'}>Выполнено</Button>
            </Box>
            <Box display="flex" flex="1" >
                <ListItemText
                    primary={obj.shop_manager_name && obj.shop_manager_status == 'done' && `Выполнен сотрудником ${obj.shop_manager_name}`}
                />
            </Box>
        </>
    );
    }  

    }

    // Менеджер оформил заказ, теперь ходок должен его выполнить
    if(obj.status >= 4){
        if(localStorage.getItem('role_id') == 20){
            return(
                <>
                    <Box display="flex" flex="1" >
                        <ListItemText
                            primary={obj.name ? obj.name : 'внести'}
                            secondary={obj.phone ? obj.phone : 'внести'}
                            primaryTypographyProps={{ 
                                sx:{ 
                                    color: localStorage.getItem('role_id') == 20 && obj.status == 6 ? 'green' : 'red', 
                                    fontWeight: localStorage.getItem('role_id') == 20 && obj.status == 6 ? 'normal' : 'bold', 
                                }
                            }}
                        />
                    </Box>
                    <Box display="flex" flex="1" >
                        <ListItemText
                            primary={obj.tg_nickname && <Link to={`https://t.me/${obj.tg_nickname.slice(1)}`} target='_blank'>{obj.tg_nickname}</Link>}
                            secondary={obj.shop ? shops.find(s => s.id == obj.shop).name : 'внести'}
                        />
                    </Box>   
                    <Box display="flex" flex="1" >
                        <ListItemText
                            primary={`${obj.price_final ? obj.price_final : 'на уточнении,'} руб.`}
                            secondary={obj.status ? statuses.find(s => s.id == obj.status).name : 'Начать работу с заказом'}
                        />
                    </Box>   
                    <Box display="flex" flex="1" >
                        {obj.status < 6 && <EditButton label="Детали заказа" />}
                    </Box>
                    <Box display="flex" flex="1" >
                        {obj.status < 6 &&  <CustomExportButton record={obj} />}
                    </Box>
                </>
            );
        } 
    }

    // Менеджер должен подтвердить и всегда видеть заказ
    if(localStorage.getItem('role_id') == 15 || localStorage.getItem('role_id') <= 2){
    return(
        <>
            <Box display="flex" flex="1" >
                <ListItemText
                    primary={obj.name ? obj.name : 'внести'}
                    secondary={obj.phone ? obj.phone : 'внести'}
                    primaryTypographyProps={{ 
                        sx:{ 
                            color: (localStorage.getItem('role_id') == 14 || localStorage.getItem('role_id') <= 2) && obj.status >= 3 ? 'green' : 'red', 
                            fontWeight: (localStorage.getItem('role_id') == 14 || localStorage.getItem('role_id') <= 2) && obj.status >= 3 ? 'normal' : 'bold', 
                        }
                    }}
                />
            </Box>
            <Box display="flex" flex="1" >
                <ListItemText
                    primary={obj.tg_nickname && <Link to={`https://t.me/${obj.tg_nickname.slice(1)}`} target='_blank'>{obj.tg_nickname}</Link>}
                    secondary={obj.shop ? shops.find(s => s.id == obj.shop).name : 'внести'}
                />
            </Box>
            <Box display="flex" flex="1" mr={1}>
                <Button onClick={() => takeOrder(obj.id)} variant='contained' disabled={obj.manager_id}>
                    {obj.manager_id ? `В работе у ${obj.manager_name}` : 'Взять в работу'}
                </Button>
            </Box>   
            <Box display="flex" flex="1" >
                <ListItemText
                    primary={`${obj.price_final ? obj.price_final : 'на уточнении,'} руб.`}
                    secondary={obj.status ? statuses.find(s => s.id == obj.status).name : 'Начать работу с заказом'}
                />
            </Box>   
            <Box display="flex" flex="1" >
                <ListItemText
                    primary={obj.shop_manager_status ? `${shop_manager_status[obj.shop_manager_status]} ${obj.shop_manager_name}` : 'Не взят в работу сотрудником магазина'}
                />
            </Box>   
            <Box display="flex" flex="1" >
                {(obj.manager_id == localStorage.getItem('id') || localStorage.getItem('role_id') <= 2) && <EditButton label="Детали заказа" />}
            </Box>
            <Box display="flex" flex="1" >
                {obj.cart_items && <CustomExportButton record={obj} />}
            </Box>
        </>
    );
    }

    return (
        <Box display="flex" flex="1">
            <ListItemText
                primary={`Пока что, заказ в обработке у другого менеджера`}
            />
        </Box> 
    );
}

const shop_manager_status = {
    in_progress: 'Взят в работу сотрудником',
    done: 'Выполнен сотрудником'
}

const ListActionsManager = () => (
    <TopToolbar>
       {/*<CreateButton
            variant="contained"
            label="Добавить заказ вручную"
            sx={{ marginLeft: 2 }}
        />*/}
    </TopToolbar>
);

const DeliveryRequestsList = () => {
    const { identity } = useGetIdentity();

    return identity ? (
        <RaList
            sx={{ marginTop: '1rem' }}
            actions={localStorage.getItem('role_id') <= 2 ? <ListActions /> : <ListActionsManager />}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            empty={false}
            sort={{ field: 'created_at', order: 'DESC' }}
            filter={localStorage.getItem('role_id') == 16 && { shop: 'lemana' }}
        >
            <ListContent />
        </RaList>
    ) : null;
};

export default DeliveryRequestsList;