import React, { useState, useEffect } from 'react';
import { endpoint, wsEndpoint } from '../App';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch,
    FunctionField,
    Loading,
    DateInput,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    DateTimeInput,
    Form
} from 'react-admin';
import {
    Box, 
    useMediaQuery,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from 'axios';
import { AccountingFillForm } from './AccountingFillForm';
import moment from 'moment';
moment.locale('ru');

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const filteredDataToExport = accounts.map(({ avatar, password, connectedToLeads, picture, ...rest }) => rest);

    const ws = XLSX.utils.json_to_sheet(filteredDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Аналитика - Выгрузка из CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Assuming YYYY-MM-DD format for the DateInput
};

const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const formatBroadcastTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

function getStartDefault() {
    return moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss');
}

function getEndDefault() {
    return moment().set({ hour: 20, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss');
}

const formatTotalBroadcastTime = (seconds) => {
    const totalHours = seconds / 3600;
    const formattedHours = Number.isInteger(totalHours) ? totalHours : totalHours.toFixed(1);
    return `${formattedHours} ч.`;
}

// Custom filter component using DateInput
const AccountingFilter = ({ setFilters, defaultStartDate, defaultEndDate, currentManagerFilter, handleSelectedManagerFilterChange }) => {
    const handleApplyFilters = (values) => {
        if (values.startDate && values.endDate) {
            setFilters({ search_start_time: values.startDate, search_end_time: values.endDate });
        }
    };

    return (
        <Form className='filterForm' onSubmit={handleApplyFilters}>
            <Box display="flex" flexDirection="row" mb={2} ml={2} alignItems="center">
                <DateTimeInput
                    label="Начало периода"
                    source="startDate"
                    defaultValue={defaultStartDate}
                    style={{ marginRight: '1rem' }}
                />
                <DateTimeInput
                    label="Окончание периода"
                    source="endDate"
                    defaultValue={defaultEndDate}
                    style={{ marginRight: '1rem' }}
                />
                <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'head_of_dep': true }} >
                  <AutocompleteInput label="Руководитель" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
                </ReferenceInput>
                <Button type="submit" variant="contained" color="primary" style={{ marginLeft: '1rem' }}>
                    Показать за период
                </Button>
            </Box>
        </Form>
    );
};

const AccountingPage = () => {
    const { identity } = useGetIdentity();
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [numDays, setNumDays] = useState(1);
    const [filters, setFilters] = useState({
        search_start_time: getStartDefault(),
        search_end_time: getEndDefault(),
    });
    const [currentManagerFilter, setCurrentManagerFilter] = useState(null);

    useEffect(() => {
        fetchData(filters);
    }, []);

    const fetchData = async (filters) => {
        setLoading(true);

        setNumDays(Math.ceil((new Date(filters.search_end_time) - new Date(filters.search_start_time)) / (1000 * 60 * 60 * 24)));

        try {
            const token = localStorage.getItem('token');
            const id = localStorage.getItem('id');
            const response = await axios.get(
                `${endpoint}/analytics`,
                {
                    params: {
                        manager_account_id: id,
                        team_members_head_id: currentManagerFilter,
                        ...filters
                    },
                    headers: {
                        Authorization: `${token}`
                    }
                }
            );

            setManagers(response.data.sort((a, b) => a.phone_extension.slice(-2).localeCompare(b.phone_extension.slice(-2))));
        } catch (error) {
            console.error('Fetch failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSetFilters = (filters) => {
        fetchData(filters);
    };

    const handleSelectedManagerFilterChange = (value) => {
        setCurrentManagerFilter(value);
    };

     // Calculate totals
     const callsPlan = numDays * 26 * managers.length;
     const broadcastPlan = numDays * 1.167 * 3600 * managers.length;
     const onlineMeetingsPlan = Math.round(numDays * 0.4) * managers.length;
     const offlineMeetingsPlan = Math.round(numDays * 0.23) * managers.length;
     const dealsPlan = Math.round(numDays * 0.1) * managers.length;   
     const totalIncomePlan = managers.reduce((sum, manager) => sum + manager.income_plan, 0);

     const callsPlanManager = numDays * 26;
     const broadcastPlanManager = numDays * 1.167 * 3600;
     const onlineMeetingsPlanManager = Math.round(numDays * 0.4);
     const offlineMeetingsPlanManager = Math.round(numDays * 0.23);
     const dealsPlanManager = Math.round(numDays * 0.1);

     //Calculate total finance with bonuses for each manager
     managers.forEach(manager => {
        let totalB = 0;
    
        if(numDays > 27) {
            if (manager.calls >= 800) { // больше 800 звонков
                totalB += 10000;
            }
            if (manager.broadcast_time >= 3600*35) { // больше 35 часов эфира
                totalB += 10000;
            }
            if (manager.online_meetings >= 12 && manager.online_meetings > 0) {
                totalB += 10000;
            }
            if (manager.offline_meetings >= 7 && manager.offline_meetings > 0) {
                totalB += 10000;
            }
            if (manager.deals >= 3 && manager.deals > 0) {
                totalB += 10000;
            }
        }
    
        manager.total_bonuses = totalB;
    });

    const totalCalls = managers.reduce((sum, manager) => sum + manager.calls, 0);
    const totalBroadcastTime = managers.reduce((sum, manager) => sum +  manager.broadcast_time, 0);
    const totalOnlineMeetings = managers.reduce((sum, manager) => sum + manager.online_meetings, 0);
    const totalOfflineMeetings = managers.reduce((sum, manager) => sum + manager.offline_meetings, 0);
    const totalDeals = managers.reduce((sum, manager) => sum + manager.deals, 0);
    const totalIncomeFact = managers.reduce((sum, manager) => sum + manager.income_fact, 0);
    const totalBonuses = managers.reduce((sum, manager) => sum + manager.total_bonuses, 0);

    return identity ? (
        <Box>
            {/*localStorage.getItem('accountant') == 'true' && <AccountingFillForm />*/}
            <Box display="flex" flexDirection="row" alignItems="start" sx={{ marginTop: '1.5rem' }}>
            <h3>Показатели KPI и премии</h3>
            <AccountingFilter 
                setFilters={handleSetFilters} 
                defaultStartDate={filters.search_start_time} 
                defaultEndDate={filters.search_end_time}
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
            />
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <List sx={{ backgroundColor: 'background.paper' }}>
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Сотрудник" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem',borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Звонки" secondary={`${totalCalls} / ${callsPlan}`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Эфир" secondary={`${formatBroadcastTime(totalBroadcastTime)} / ${formatTotalBroadcastTime(broadcastPlan)}`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Онлайн-встречи" secondary={`${totalOnlineMeetings} / ${onlineMeetingsPlan}`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Оффлайн-встречи" secondary={`${totalOfflineMeetings} / ${offlineMeetingsPlan}`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Сделки (кол-во)" secondary={`${totalDeals} / ${dealsPlan}`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        {/*<Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Поступления (₽)" secondary={`Факт / План`}/>
                        </Box>*/}
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Премия (итого, ₽)" secondary={`${totalBonuses} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                    </ListItem>
            {managers.map(manager => {

                    return (
                        <ListItem key={manager.id} sx={{ padding: 0, alignItems: 'stretch' }}>
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <ListItemText 
                                    primary={`${manager.name}`} secondary={`${manager.phone_extension}`} 
                                    primaryTypographyProps={{ 
                                        sx:{ fontWeight: manager.head_of_dep ? 'bold' : 'normal' }
                                    }}
                                    sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                                />
                            </Box>

                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={manager.calls} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold', color: manager.calls >= callsPlanManager ? '#01BF00' : 'red' }
                                        }}
                                    />
                                </Box>
                                <Box display="flex" flex={ manager.calls >= callsPlanManager && numDays > 27 ? 1 : 3} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`/ ${callsPlanManager}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: manager.calls >= callsPlanManager ? 'bold' : 'normal', color: manager.calls >= callsPlanManager ? '#01BF00' : 'default' }
                                        }} 
                                    />
                                </Box>
                            </Box>

                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={formatBroadcastTime(manager.broadcast_time)} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold', color: manager.broadcast_time >= broadcastPlanManager ? '#01BF00' : 'red' }
                                        }}
                                    />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`/ ${formatTotalBroadcastTime(broadcastPlanManager)}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: manager.broadcast_time >= broadcastPlanManager ? 'bold' : 'normal', color: manager.broadcast_time >= broadcastPlanManager ? '#01BF00' : 'default' }
                                        }} 
                                    />
                                </Box>
                            </Box>

                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={manager.online_meetings} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold', color: manager.online_meetings >= onlineMeetingsPlanManager ? '#01BF00' : 'red' }
                                        }}
                                    />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`/ ${onlineMeetingsPlanManager}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: manager.online_meetings >= onlineMeetingsPlanManager ? 'bold' : 'normal', color: manager.online_meetings >= onlineMeetingsPlanManager ? '#01BF00' : 'default' }
                                        }} 
                                    />
                                </Box>
                            </Box>

                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={manager.offline_meetings} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold', color: manager.offline_meetings >= offlineMeetingsPlanManager ? '#01BF00' : 'red' }
                                        }}
                                    />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`/ ${offlineMeetingsPlanManager}`}
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: manager.offline_meetings >= offlineMeetingsPlanManager ? 'bold' : 'normal', color: manager.offline_meetings >= offlineMeetingsPlanManager ? '#01BF00' : 'default' }
                                        }} 
                                    />
                                </Box>
                            </Box>

                            {/* Сделки */}
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={manager.deals} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold', color: manager.deals >= dealsPlanManager ? '#01BF00' : 'red' }
                                        }}
                                    />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`/ ${dealsPlanManager}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: manager.deals >= dealsPlanManager ? 'bold' : 'normal', color: manager.deals >= dealsPlanManager ? '#01BF00' : 'default' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/* Поступления */}
                            {/*<Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center">
                                    <ListItemText 
                                        primary={`${manager.income_fact}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold', color: manager.income_fact >=  manager.income_plan && manager.income_fact > 0  ? '#01BF00' : 'red' }
                                        }}
                                    />
                                </Box>
                                <Box display="flex" flex={3} justifyContent="start" alignItems="center">
                                    <ListItemText 
                                        primary={`/ ${manager.income_plan} ₽`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: manager.income_fact >=  manager.income_plan ? 'bold' : 'normal', color: manager.income_fact >=  manager.income_plan && manager.income_fact > 0 ? '#01BF00' : 'default' }
                                        }}
                                    />
                                </Box>
                            </Box>*/}

                            {/* Итого */}
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${manager.total_bonuses} ₽`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>
                            </Box>
                        </ListItem>
                    );
                })}
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderTop: '1px solid lightgrey', borderBottom: '1px solid lightgrey'  }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Итого:" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Звонки" primary={`${totalCalls} / ${callsPlan}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalCalls >= callsPlan ? 'bold' : 'normal', color: totalCalls >= callsPlan ? '#01BF00' : 'default' }
                            }}
                            sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Эфир" primary={`${formatBroadcastTime(totalBroadcastTime)} / ${formatTotalBroadcastTime(broadcastPlan)}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalBroadcastTime >= broadcastPlan ? 'bold' : 'normal', color: totalBroadcastTime >= broadcastPlan ? '#01BF00' : 'default' }
                            }}
                            sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Онлайн-встречи" primary={`${totalOnlineMeetings} / ${onlineMeetingsPlan}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalOnlineMeetings >= onlineMeetingsPlan ? 'bold' : 'normal', color: totalOnlineMeetings >= onlineMeetingsPlan ? '#01BF00' : 'default' }
                            }}
                            sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Оффлайн-встречи" primary={`${totalOfflineMeetings} / ${offlineMeetingsPlan}`}
                            primaryTypographyProps={{ 
                                sx:{ fontWeight: totalOfflineMeetings >= offlineMeetingsPlan ? 'bold' : 'normal', color: totalOfflineMeetings >= offlineMeetingsPlan ? '#01BF00' : 'default' }
                            }}
                            sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                            />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Сделки (кол-во)" primary={`${totalDeals} / ${dealsPlan}`} 
                             primaryTypographyProps={{ 
                                sx:{ fontWeight: totalDeals >= dealsPlan ? 'bold' : 'normal', color: totalDeals >= dealsPlan ? '#01BF00' : 'default' }
                            }}
                            sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                            /> 
                        </Box>
                         {/*<Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Поступления (₽)" primary={`${totalIncomeFact} / ${totalIncomePlan} ₽`} 
                             primaryTypographyProps={{ 
                                sx:{ fontWeight: 'bold', color: totalIncomeFact >= totalIncomePlan ? '#01BF00' : 'default' }
                            }}
                            /> 
                        </Box>*/}
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Премия (итого, ₽)" primary={`${totalBonuses} ₽`} 
                             primaryTypographyProps={{ 
                                sx:{ fontWeight: totalDeals >= dealsPlan ? 'bold' : 'normal', color: totalDeals >= dealsPlan ? '#01BF00' : 'default' }
                            }}
                            sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                            /> 
                        </Box>
                    </ListItem>
                </List>
            )}
            <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>* Премии рассчитываются при выборе периода 28 дней и более.</div>
        </Box>
    ) : (
        <Loading />
    );
};

const ListActions = () => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

    </TopToolbar>
);

export default AccountingPage;
