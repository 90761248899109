// PopupContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a context for the popup
const PopupContext = createContext();

// Hook to use the popup context
export const usePopup = () => {
    return useContext(PopupContext);
};

// PopupProvider component that provides the context value
export const PopupProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [popupData, setPopupData] = useState(null); // Store additional data

    const openPopup = (data = null) => {
        setPopupData(data); // Store the passed data
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
        setPopupData(null); // Clear data on close
    };

    return (
        <PopupContext.Provider value={{ isOpen, openPopup, closePopup, popupData }}>
            {children}
        </PopupContext.Provider>
    );
};
