import * as React from 'react';
import {
    EditBase,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
} from 'react-admin';
import { Card, CardContent, Box, Button } from '@mui/material';

import { Avatar } from '../contacts/Avatar';
import { LeadScreenWebsiteInputs as Inputs } from './LeadScreenWebsiteInputs';
import '../crm.css';

const LeadScreenWebsiteEdit = () => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const handleDelete = async (id) => {
        // Fetch the existing record to get the data before delete
        const existingRecord = await dataProvider.getOne('leadScreenWebsites', { id });

        // Modify the data or perform additional actions
        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: 'leadScreenWebsites'
        };

        // Use dataProvider to send the delete request with modified data
        await dataProvider.create('delete', { data: modifiedData });

        // You can also perform additional actions after delete if needed
        //console.log(`Deleted record with ID: ${id}`);
        redirect(`/leadScreenWebsites`);
    };

    return (
        <EditBase redirect="list" 
             mutationMode="optimistic"
             transform={(data) => ({
                ...data,
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            })}
        >
            <EditContent onDelete={handleDelete} />
        </EditBase>
    );
}

const EditContent = ({ onDelete }) => {
    const { isLoading, record } = useEditContext();

    if (isLoading || !record) return null;

    return (
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <Inputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <CustomToolbar onDelete={() => onDelete(record.id)}/>
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};

const CustomToolbar = ({ onDelete }) => {
    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            {localStorage.getItem('role_id') <= 2 ? <Button style={{ color: 'red' }} onClick={onDelete} >Удалить</Button> : null}
        </Toolbar>
    );
};

export default LeadScreenWebsiteEdit;