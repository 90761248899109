import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const MessagesTemplateInputs = () => {
    const navigate = useNavigate();

    return (<Box flex="1" mt={-1}>

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
			<h3>Шаблон сообщения по whatsapp/смс</h3>
		</Box>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Короткое название сообщения" source="name" helperText="Для удобства выбора шаблона в карточке лида" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Текст сообщения" source="text" helperText="Любое сообщение включая разрывы строк и смайлики" fullWidth multiline validate={required()} />
            </Grid>
        </Grid>

    </Box>);
};
