import * as React from 'react';
import { ReferenceField, useRedirect } from 'react-admin';
import { Box, Card, Typography, Button } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { CallButton} from '../calls/CallButton';
import { LogoField } from '../companies/LogoField';
import { typeChoices, getStatusColor } from './types';

export const DealCard = ({ request, index, inactive_stage, drag_blocked_stage }) => {
    const redirect = useRedirect();
    if (!request) return null;

    const handleClick = () => {
        redirect(`/requests/${request.id}`);
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        window.open(`#/requests/${request.id}`, '_blank');
    };
    
    // Check if task_time is before current time
    //const isTaskTimeBeforeCurrentTime = request.task_time && new Date(request.task_time) < new Date();
    var isTaskTimeBeforeCurrentTime = (request.task_time && new Date(request.task_time) < new Date()) || !request.task_time || (request.task_time && request.task_time == "");
    if(inactive_stage == true){
        isTaskTimeBeforeCurrentTime = false;
    }

    function formatTaskTime() {
        if (request.task_time && request.task_time !== '' && !inactive_stage) {
            return new Date(request.task_time).toLocaleString('ru-RU');
        } else if (!inactive_stage) {
            return 'Поставьте задачу';
        } else {
            return 'Заявка обработана';
        }
    }

    return (
        <Draggable draggableId={String(request.id)} index={index} isDragDisabled={drag_blocked_stage}>
            {(provided, snapshot) => (
                <Box
                    sx={{ marginBottom: 1,}}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={handleClick}
                    onContextMenu={handleContextMenu}
                >
                    <Card
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging
                                ? 'rotate(-2deg)'
                                : '',
                                border: isTaskTimeBeforeCurrentTime ? '4px solid #FF5005' : 'none',
                                animation: isTaskTimeBeforeCurrentTime ? 'blinkingBorder 1s infinite' : 'none',
                        }}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
<Box padding={1} display="flex">
    <Box
        sx={{
            borderLeft: request.status ? `4px solid ${getStatusColor(request.status)}` : null,
            padding: request.status ? '0 16px' : null,
            marginRight: '16px', 
        }}
    >
        {/*<Typography variant="body2" style={{ color: getStatusColor(request.status)}}>{request.status ? typeChoices.find(status => status.id === request.status).name : null}</Typography>
        <Typography variant="body2" gutterBottom>
            #{request.number}
        </Typography>*/}

        <Typography variant="body1" fontWeight="bold" gutterBottom>
            {request.name}
        </Typography>
        {request.realty_id && <Typography variant="body2" gutterBottom>
            {request.realty_title}
        </Typography>}
        <CallButton contact_phone={request.phone} type='link' confirmPopup={true} telegram_username={request.telegram_username} />
        <br/>
        <Typography variant="caption" color="textSecondary">
            {formatTaskTime()}
        </Typography>
        <br/>
        <Typography variant="caption" color="textSecondary">
            {request.manager_name}
        </Typography>
    </Box>
</Box>
                    </Card>
                </Box>
            )}
        </Draggable>
    );
};
