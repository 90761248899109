export const categories = [
    { id: '1', name: 'Недвижимость' },
    { id: '2', name: 'Квартиры' },
    { id: '3', name: 'Комнаты' },
    { id: '4', name: 'Дома, дачи, коттеджи' },
    { id: '5', name: 'Земельные участки' },
    { id: '6', name: 'Гаражи и машиноместа' },
    { id: '7', name: 'Коммерческая недвижимость' },
    { id: '8', name: 'Недвижимость за рубежом' },
    { id: '12', name: 'Услуги' },
    { id: '13', name: 'Предложения услуг' },
    { id: '21', name: 'Транспорт' },
    { id: '22', name: 'Автомобили' },
];

export const nedvigimostTypes = [
    { id: '1', name: 'Продам' },
    { id: '2', name: 'Сдам' },
    { id: '3', name: 'Куплю' },
    { id: '4', name: 'Сниму' },
];

export const sources = [
    { id: '1', name: 'avito.ru' },
    { id: '3', name: 'realty.yandex.ru' },
    { id: '4', name: 'cian.ru' },
    { id: '5', name: 'sob.ru' },
    { id: '6', name: 'youla.io' },
    { id: '7', name: 'n1.ru' },
    { id: '10', name: 'moyareklama.ru' },
    { id: '11', name: 'domclick.ru' },
];

export const cities = [
    'Тула',
    'Тульская область',
    'Москва',
    'Московская область',
    'Санкт-Петербург',
    'Севастополь',
    'Адыгея',
    'Алтай',
    'Алтайский край',
    'Амурская область',
    'Архангельская область',
    'Астраханская область',
    'Башкортостан',
    'Белгородская область',
    'Брянская область',
    'Бурятия',
    'Владимирская область',
    'Волгоградская область',
    'Вологодская область',
    'Воронежская область',
    'Дагестан',
    'Еврейская автономная область',
    'Забайкальский край',
    'Ивановская область',
    'Ингушетия',
    'Иркутская область',
    'Кабардино-Балкария',
    'Калининградская область',
    'Калмыкия',
    'Калужская область',
    'Камчатский край',
    'Карачаево-Черкесия',
    'Карелия',
    'Кемеровская область',
    'Кировская область',
    'Коми',
    'Костромская область',
    'Краснодарский край',
    'Красноярский край',
    'Крым',
    'Курганская область',
    'Курская область',
    'Ленинградская область',
    'Липецкая область',
    'Магаданская область',
    'Марий Эл',
    'Мордовия',
    'Мурманская область',
    'Нижегородская область',
    'Новгородская область',
    'Новосибирская область',
    'Омская область',
    'Оренбургская область',
    'Орловская область',
    'Пензенская область',
    'Пермский край',
    'Приморский край',
    'Псковская область',
    'Ростовская область',
    'Рязанская область',
    'Самарская область',
    'Саратовская область',
    'Саха (Якутия)',
    'Сахалинская область',
    'Свердловская область',
    'Северная Осетия',
    'Смоленская область',
    'Ставропольский край',
    'Тамбовская область',
    'Татарстан',
    'Тверская область',
    'Томская область',
    'Тыва',
    'Тюменская область',
    'Удмуртия',
    'Ульяновская область',
    'Хабаровский край',
    'Хакасия',
    'Ханты-Мансийский АО',
    'Челябинская область',
    'Чеченская Республика',
    'Чувашия',
    'Чукотский АО',
    'Ямало-Ненецкий АО',
    'Ярославская область',
    'Абакан',
    'Анапа',
    'Архангельск',
    'Астрахань',
    'Барнаул',
    'Белгород',
    'Благовещенск',
    'Брянск',
    'Великий Новгород',
    'Владивосток',
    'Владикавказ',
    'Владимир',
    'Волгоград',
    'Вологда',
    'Воронеж',
    'Грозный',
    'Екатеринбург',
    'Иваново',
    'Ижевск',
    'Иркутск',
    'Казань',
    'Калининград',
    'Калуга',
    'Кемерово',
    'Киров',
    'Кострома',
    'Краснодар',
    'Красноярск',
    'Курган',
    'Курск',
    'Липецк',
    'Магадан',
    'Махачкала',
    'Мурманск',
    'Нижний Новгород',
    'Новосибирск',
    'Омск',
    'Оренбург',
    'Орёл',
    'Пенза',
    'Пермь',
    'Петрозаводск',
    'Псков',
    'Ростов-на-Дону',
    'Рязань',
    'Самара',
    'Саранск',
    'Саратов',
    'Симферополь',
    'Смоленск',
    'Сочи',
    'Ставрополь',
    'Сургут',
    'Тамбов',
    'Тверь',
    'Томск',
    'Тюмень',
    'Улан-Удэ',
    'Ульяновск',
    'Уфа',
    'Хабаровск',
    'Ханты-Мансийск',
    'Чебоксары',
    'Челябинск',
    'Чита',
    'Южно-Сахалинск',
    'Якутск',
    'Ярославль'
];