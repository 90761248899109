import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    NumberInput,
    DateInput,
    useEditContext
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { endpoint } from '../App';

export const RealtyPublishInputs = () => {
    const editContext = useEditContext();
    const navigate = useNavigate();

    return (<Box flex="1" mt={-1} gap='1rem' >

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
			<h3>Объявление по недвижимости к публикации на площадках</h3>
		</Box>

        <h4>Авито, ЦИАН, Яндекс, Домклик (пока здесь можно публиковать только продажу квартир во вторичке)</h4>

        {!editContext.record ?
        <h5 style={{ color: 'red', maxWidth: '40rem'}}>Фото можно добавить только при первичной публикации. Только сейчас. Далее, при редактировании, вы не сможете изменить или добавить фото. Чтобы поменять или добавить фото можно удалить весь объект и создать новый, заполнив все поля заново.</h5>
        :
        <h5 style={{ color: 'red', maxWidth: '40rem'}}>Если хотите загрузить или поменять фото, удалите этот объект и создайте заново.</h5>
        }

        {editContext.record ? 
        <Box display="flex" flexDirection="column">
        <h5>Фото:</h5>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
        {editContext.record.photos && 
            editContext.record.photos.map(photo => (

                <img src={`${endpoint}/${photo.file_path}`} style={{width: '20rem'}} />
            ))
        }
        </Box>
        </Box>
        :
        <ImageInput source="photos" multiple label="Фотографии объекта" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        }


        {editContext.record && editContext.record.floorPlanFile ? 
            <Box display="flex" flexDirection="column"  sx={{ minWidth: '90%' }}>
                <h5>Фото планировки :</h5>
                <img src={`${endpoint}/${editContext.record.floorPlanFile.file_path}`} style={{width: '20rem'}} />
            </Box> 
        : 
        <ImageInput source="floorPlanFile" label="Фото планировки (только одно)" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        }
        

        <h4>Авито</h4>

        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>

        <TextInput source="description" multiline sx={{ minWidth: '90%' }} label="Описание (от 15 до 3000 символов)" validate={required()} />

        <TextInput source="address" sx={{ minWidth: '90%' }} label="Укажите адрес точно как на Яндекс Картах (не более 256 символов)" validate={required()} />

        <SelectInput source="operationType" label="Тип объявления" sx={{ minWidth: '40%' }} choices={[
                    { id: 'sale', name: 'Продам' },
                    { id: 'rent', name: 'Аренда' }
        ]} validate={required()} />
        <SelectInput source="marketType" label="Принадлежность квартиры к рынку" sx={{ minWidth: '40%' }} choices={[
            { id: 'secondary', name: 'Вторичка' },
            //{ id: 'new', name: 'Новостройка' }
        ]} validate={required()} />
        <SelectInput source="houseType" label="Тип дома" sx={{ minWidth: '40%' }} choices={[
                    { id: 'brick', name: 'Кирпичный' },
                    { id: 'panel', name: 'Панельный' },
                    { id: 'block', name: 'Блочный' },
                    { id: 'monolithic', name: 'Монолитный' },
                    { id: 'monolithicBrick', name: 'Монолитно-кирпичный' },
                    { id: 'wooden', name: 'Деревянный' }
        ]} validate={required()} />
        <NumberInput source="floor" sx={{ minWidth: '40%' }} label="Этаж, на котором находится объект" validate={required()} />
        <NumberInput source="floors" sx={{ minWidth: '40%' }} label="Количество этажей в доме" validate={required()} />
                <SelectInput source="rooms" label="Количество комнат" sx={{ minWidth: '40%' }} choices={[
                    { id: 'studio', name: 'Студия' },
                    { id: '1', name: '1' },
                    { id: '2', name: '2' },
                    { id: '3', name: '3' },
                    { id: '4', name: '4' },
                    { id: '5', name: '5' },
                    { id: '6', name: '6' },
                    { id: '7', name: '7' },
                    { id: '8', name: '8' },
                    { id: '9', name: '9' },
                    { id: '10', name: '10 и более' },
                    { id: 'free', name: 'Своб. планировка' }
                ]} validate={required()} />
                <NumberInput source="square" sx={{ minWidth: '40%' }} label="Общая площадь объекта недвижимости в кв. метрах" validate={required()} />
                <NumberInput source="kitchenSpace" sx={{ minWidth: '40%' }} label="Площадь кухни, в кв. метрах (от 2 до 100)" validate={required()} />
                <SelectInput source="status" label="Статус недвижимости" sx={{ minWidth: '40%' }} choices={[
                    { id: 'apartment', name: 'Квартира' },
                    { id: 'apartments', name: 'Апартаменты' }
                ]} validate={required()} />
                <SelectInput source="roomType" label="Тип комнат" sx={{ minWidth: '40%' }} choices={[
                    { id: 'Изолированные', name: 'Изолированные' },
                    { id: 'Смежные', name: 'Смежные' }
                ]} validate={required()} />
                <SelectInput source="renovation" label="Ремонт" sx={{ minWidth: '40%' }} choices={[
                    { id: 'required', name: 'Требуется' },
                    { id: 'cosmetic', name: 'Косметический' },
                    { id: 'euro', name: 'Евро' },
                    { id: 'designer', name: 'Дизайнерский' }
                ]} validate={required()} />
                <SelectInput source="propertyRights" label="Право собственности" sx={{ minWidth: '40%' }} choices={[
                    { id: 'owner', name: 'Собственник' },
                    { id: 'agent', name: 'Посредник' }
                ]} validate={required()} />
                <SelectInput source="dealType" label="Тип сделки" sx={{ minWidth: '40%' }} choices={[
                    { id: 'direct', name: 'Прямая продажа' },
                    { id: 'alternative', name: 'Альтернативная' }
                ]} validate={required()} />

        </Box>

        <h4>ЦИАН</h4>

            <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                <TextInput source="title" sx={{ minWidth: '90%' }} label="Заголовок объявления (от 8 до 33 символов)" />
                <TextInput source="countryCode" sx={{ minWidth: '40%' }} label="Код страны" initialValue="+7" defaultValue="+7" validate={required()}/>
                <TextInput source="phoneNumber" sx={{ minWidth: '40%' }} label="Номер телефона (без +7)" validate={required()}/>
                <TextInput source="cadastralNumber" sx={{ minWidth: '40%' }} label="Кадастровый номер" />
                <NumberInput source="flatRoomsCount" sx={{ minWidth: '40%' }} label="Количество комнат" validate={required()}/>
                <BooleanInput source="isEuroFlat" sx={{ minWidth: '40%' }} label="Европланировка" />
                <NumberInput source="totalArea" sx={{ minWidth: '40%' }} label="Общая площадь, м²" validate={required()}/>
                <NumberInput source="floorNumber" sx={{ minWidth: '40%' }} label="Этаж реализуемого объекта" validate={required()}/>
                <NumberInput source="floorsCount" sx={{ minWidth: '40%' }} label="Количество этажей в доме" validate={required()}/>
                <NumberInput source="loggiasCount" sx={{ minWidth: '40%' }} label="Количество лоджий" />
                <NumberInput source="balconiesCount" sx={{ minWidth: '40%' }} label="Количество балконов" />
                <NumberInput source="separateWcsCount" sx={{ minWidth: '40%' }} label="Количество раздельных санузлов" />
                <NumberInput source="combinedWcsCount" sx={{ minWidth: '40%' }} label="Количество совмещённых санузлов" />
                <SelectInput source="repairType" label="Тип ремонта" sx={{ minWidth: '40%' }} choices={[
                    { id: 'cosmetic', name: 'Косметический' },
                    { id: 'design', name: 'Дизайнерский' },
                    { id: 'euro', name: 'Евроремонт' },
                    { id: 'no', name: 'Без ремонта' },
                ]} />
                <BooleanInput source="hasFurniture" sx={{ minWidth: '40%' }} label="Есть мебель" />
                <BooleanInput source="hasPhone" sx={{ minWidth: '40%' }} label="Наличие телефона" />
                <BooleanInput source="hasRamp" sx={{ minWidth: '40%' }} label="Наличие пандуса" />
                <BooleanInput source="isInHiddenBase" sx={{ minWidth: '40%' }} label="Размещение в закрытой базе" />
                <TextInput source="videos" sx={{ minWidth: '40%' }} label="Видео (URL)" />
                <NumberInput source="price" sx={{ minWidth: '40%' }} label="Цена объекта в рублях" validate={required()} />
                <BooleanInput source="mortgageAllowed" sx={{ minWidth: '40%' }} label="Ипотека" />
                <SelectInput source="saleType" label="Тип продажи" sx={{ minWidth: '40%' }} choices={[
                    { id: 'alternative', name: 'Альтернатива' },
                    { id: 'free', name: 'Свободная продажа' },
                ]} />
                <NumberInput source="agentBonusValue" sx={{ minWidth: '40%' }} label="Бонус агенту (значение)" />
                <SelectInput source="agentBonusPaymentType" label="Тип оплаты бонуса агенту" sx={{ minWidth: '40%' }} choices={[
                    { id: 'fixed', name: 'Фиксированный' },
                    { id: 'percent', name: 'Процент' },
                ]} />
                <SelectInput source="agentBonusCurrency" label="Валюта бонуса агенту" sx={{ minWidth: '40%' }} choices={[
                    { id: 'eur', name: 'Евро' },
                    { id: 'rur', name: 'Рубль' },
                    { id: 'usd', name: 'Доллар' },
                ]} />
                <TextInput source="homeOwnerName" sx={{ minWidth: '40%' }} label="ФИО собственника" />
                <TextInput source="homeOwnerPhone" sx={{ minWidth: '40%' }} label="Телефон собственника" />
                <TextInput source="exactAddress" sx={{ minWidth: '40%' }} label="Точный адрес объекта" />

            </Box>

        <h4>Яндекс.Недвижимость</h4>

        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
        <SelectInput
            source="yaType"
            sx={{ minWidth: '40%' }}
            label="Тип"
            choices={[
                { id: 'flat', name: 'Квартира' },
                { id: 'house', name: 'Дом' },
                { id: 'cottage', name: 'Коттедж' },
                { id: 'lot', name: 'Участок' },
                { id: 'room', name: 'Комната' },
                { id: 'townhouse', name: 'Таунхаус' },
                { id: 'duplex', name: 'Дуплекс' },
                { id: 'garage', name: 'Гараж' },
            ]}
            validate={required()}
        />
        <TextInput source="city" sx={{ minWidth: '40%' }} label="Город" validate={required()} />
        <TextInput source="apartmentNumber" sx={{ minWidth: '40%' }} label="Номер квартиры" validate={required()} />
        <TextInput source="metroName" sx={{ minWidth: '40%' }} label="Название метро" />
        <NumberInput source="timeOnFoot" sx={{ minWidth: '40%' }} label="Время до метро пешком (минуты)" />
        <NumberInput source="timeOnTransport" sx={{ minWidth: '40%' }} label="Время до метро на транспорте (минуты)" />
        </Box>

    </Box>);
};
