import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    SelectInput,
    TopToolbar,
    useGetIdentity,
    useRefresh,
    useGetList,
    Form,
    required,
    useListContext,
    RecordContextProvider,
    EditButton,
    Pagination,
    SimpleListLoading,
    ReferenceField,
    TextField,
    SimpleList,
    Loading,
    InfiniteList,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceOneField,
    FunctionField
} from 'react-admin';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import { List as MUIList,ListItem, ListItemText, Box, Dialog, DialogContent, Typography, Divider, Link as MUILink, IconButton, useMediaQuery} from '@mui/material';
import { DealListContent } from './DealListContent';
import { RequestCreate } from './RequestCreate';
import { RequestEdit } from './RequestEdit';
import { OnlyMineInput } from './OnlyMineInput';
import { typeChoices, getStatusColor } from './types';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { CallButton} from '../calls/CallButton';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: requests,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const ws = XLSX.utils.json_to_sheet(requests);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Заявки - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />} sx={{ marginLeft: 2 }}>
      Выгрузить в Excel
    </Button>
  );
};

const RequestList = () => {
    const { identity } = useGetIdentity();
    const location = useLocation();
    const refresh = useRefresh();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [currentFunnel, setCurrentFunnel] = useState(/*{"id": "123", "name": "fallback", "stages": []}*/);
    const [currentManagerFilter, setCurrentManagerFilter] = useState();
    const [currentStageFilter, setCurrentStageFilter] = useState();
    var filtersObject = {manager_account_id: '', stage: ''};
    const listContentRef = useRef();
    const filters = [
        {id: 1, name: 'Обычное', order: 'DESC', field: 'updated_at'}, 
        {id: 2, name: 'С конца', order: 'ASC', field: 'updated_at'},
    ];
    const [currentFilter, setCurrentFilter] = useState(filters[0]);

    const handleFilterChange = (value) => {
        setCurrentFilter(filters.find((item) => item.id === value.target.value));
        if (listContentRef.current) {
            listContentRef.current.setSort1(filters.find((item) => item.id === value.target.value).field, filters.find((item) => item.id === value.target.value).order);
        }
    };

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'name', order: 'ASC' },
        }
    );

    useEffect(() => {
        const storedManagerFilter= localStorage.getItem('currentManagerFilter');
        if(storedManagerFilter){
            setCurrentManagerFilter(storedManagerFilter);
            filtersObject.manager_account_id = storedManagerFilter;
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
        if(storedManagerFilter == ""){
            setCurrentManagerFilter("");
            filtersObject.manager_account_id = "";
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
    }, [currentManagerFilter]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const funnel_id = queryParams.get('funnel_id');
        if(funnels && funnel_id){
            setCurrentFunnel(funnels.find((item) => item.id === funnel_id));
        }
        if(funnels && !funnel_id){
            const storedFunnelId = localStorage.getItem('currentFunnelId');
            if (funnels && storedFunnelId) {
              const selectedFunnel = funnels.find((item) => item.id === storedFunnelId);
              setCurrentFunnel(selectedFunnel || funnels[0]);
            } else if (funnels && funnels.length > 0) {
              setCurrentFunnel(funnels[0]);
            }
        }
    }, [funnels, location.search]);

    if(isLoadingFunnels || !currentFunnel || !currentFunnel.stages || !identity) return null;

    const handleSelectChange = (value) => {
         console.log('Selected value:', value.target.value);
         setCurrentFunnel(funnels.find((item) => item.id === value.target.value));
    };

    const handleSelectedManagerFilterChange = (value) => {
        //setCurrentFilter(filters.find((item) => item.id === value.target.value));
        setCurrentManagerFilter(value);
        localStorage.setItem('currentManagerFilter', value);
        filtersObject.manager_account_id = value;
        filtersObject.stage = currentStageFilter;
        if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };
/*
лиды без задач
лиды с просроченными от самой старой по времени
новые
 */
    return (
        <>
            <Box display="flex" flexDirection="column" >
            <List
            sx={{ marginTop: '1rem' }}
            resource="requests"
            actions={<RequestsActions 
                isSmall={isSmall} 
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
                filters={filters} 
                currentFilter={currentFilter} 
                handleFilterChange={handleFilterChange}
                currentFunnel={currentFunnel}
                />}
            perPage={50}
            filter={{'sales_id_for_requests': localStorage.getItem('id')}} 
            filters={/*localStorage.getItem('role_id') <= 3 ? requestsFilters :*/ requestsFiltersForManagers}
            sort={{ field: 'updated_at', order: 'DESC' }}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
            >
             <div style={{ marginBottom: '2rem' }}>
             <div style={{ position: 'absolute', top: 0, left: 0 }}>
             <Pagination rowsPerPageOptions={[25, 50, 100]} />
             </div>
             </div>
                <RequestListContent funnels={funnels} funnelId={currentFunnel.id} ref={listContentRef} />
            </List></Box> 
        </>
    );
};

const requestsFilters = [
    <SearchInput source="q" alwaysOn />,
    <OnlyMineInput alwaysOn />,
    <SelectInput source="status" choices={typeChoices} />,
];
const requestsFiltersForManagers = [
     <SearchInput source="q" alwaysOn />,
     <SelectInput source="status" choices={typeChoices} />,
];

const RequestsActions = ({isSmall, handleSelectedManagerFilterChange, currentManagerFilter, filters, currentFilter, handleFilterChange, currentFunnel }) => {
    return (
        <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        
            <Form className='filterForm'>
            <SelectInput label="Отображение" source="view" choices={filters} defaultValue={currentFilter.id} onChange={handleFilterChange} validate={required()} helperText={null} />
            </Form>  

            {localStorage.getItem('role_id') <= 3 ?
            <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
            </Form> : null}

            {localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null}
            <CreateButton
                variant="outlined"
                label="Новый клиент"
                sx={{ marginLeft: 2 }}
            />
        </TopToolbar>
    );
};

const RequestListContent = forwardRef(( props, ref) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        data: requests,
        isLoading, isFetching,
        onToggleItem,
        selectedIds,
        setFilters,
        setSort
    } = useListContext();

    const setListFilter = (filtersObject) => {
        setFilters(filtersObject, false);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (filtersObject) => setListFilter(filtersObject),
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));

    const setSort1 = (field1, order1) => {
        setSort({ field: field1, order: order1 });
    };

    if (isLoading || isFetching) {
        return <Loading />
    }

    const now = Date.now();

    return (
        <>
             {isSmall ? 
                         <SimpleList
                            primaryText={request => request.task_time && request.task_time !== '' ? new Date(request.task_time).toLocaleString('ru-RU') : ''}
                            secondaryText={request => `Клиент: ${request.name}`}
                            tertiaryText={request => `Менеджер: ${request.manager_name}`}
                            linkType={"edit"}   
                            rowSx={request => ({
                                borderLeft: request.status ? `4px solid ${getStatusColor(request.status)}` : null,
                                padding: '0 16px',
                                marginRight: '16px', 
                                border: (request.task_time && new Date(request.task_time) < new Date()) || !request.task_time || (request.task_time && request.task_time == "") ? '4px solid red' : 'none',
                                animation: (request.task_time && new Date(request.task_time) < new Date()) || !request.task_time || (request.task_time && request.task_time == "") ? 'blinkingBorder 1s infinite' : 'none',
                            })}
                         /> :
            <MUIList>
                {requests.map(request => { 
                    // Check if task_time is before current time
                    var inactive_stage = props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.request_done;
                    var isTaskTimeBeforeCurrentTime = (request.task_time && new Date(request.task_time) < new Date()) || !request.task_time || (request.task_time && request.task_time == "");
                    if(inactive_stage == true){
                        isTaskTimeBeforeCurrentTime = false;
                    }
                    function formatTaskTime() {
                        if (request.task_time && request.task_time !== '' && !inactive_stage) {
                            return new Date(request.task_time).toLocaleString('ru-RU');
                        } else if (!inactive_stage) {
                            return 'Поставьте задачу';
                        } else {
                            return 'Заявка обработана';
                        }
                    }
                    return(
                    <RecordContextProvider key={request.id} value={request}>
                        <ListItem
                            button
                            component={Link}
                            to={`/requests/${request.id}`}
                             sx={{
                                borderLeft: request.status ? `4px solid ${getStatusColor(request.status)}` : null,
                                padding: '0 16px',
                                marginRight: '16px', 
                                border: isTaskTimeBeforeCurrentTime ? '4px solid red' : 'none',
                                animation: isTaskTimeBeforeCurrentTime ? 'blinkingBorder 1s infinite' : 'none',
                            }}
                        >
                            <Box display="flex" flex={1} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<Typography variant="body2">{`Дата создания: ${new Date(request.created_at).toLocaleString('ru-RU')}`}</Typography>}
                                secondary={request.updated_at ? `Дата обновления: ${new Date(request.updated_at).toLocaleString('ru-RU')}` : null}
                            />
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                secondary={<Box>
                                    {/*<Typography variant="body2" style={{ color: getStatusColor(request.status)}}>{request.status ? typeChoices.find(status => status.id === request.status).name : null}</Typography>*/}
                                    <Typography variant="body2">{formatTaskTime()}</Typography>
                                </Box>}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<Typography sx={{ 
                                    border: `1.5px solid ${props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.color}`,
                                    borderRadius: '0.5rem',
                                    padding: '0 0.5rem',
                                    width: 'fit-content'
                                }}>{props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.name || ''}</Typography>}
                                secondary={props.funnels.find((item) => item.id === request.funnel_id)?.name || ''}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={request.name}
                                secondary={request.manager_name}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<CallButton contact_phone={request.phone} type='link' confirmPopup={true} telegram_username={request.telegram_username} />}
                                /*secondary={<ReferenceOneField reference="deals" target="request_id">
                                    <FunctionField
                                        source="document_name"
                                        render={record => (
                                       
                                            <Link to={`/deals/${record.id}`}>
                                                <Button variant="contained" color="primary">
                                                К сделке №{record.document_name}
                                                </Button>
                                            </Link>
                                        )}
                                    />
                                </ReferenceOneField>}*/
                            />
                            </Box>
                        </ListItem>
                    </RecordContextProvider>
                );
                })}
            </MUIList>}
        </>
    );
});

export default RequestList;
