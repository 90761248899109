import * as React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {
    useRecordContext,
    useListContext,
    useCreate,
    useUpdate,
    useNotify,
    useGetIdentity,
    Identifier,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    EditBase,
    EditButton,
    SaveButton,
    SimpleForm,
    useEditContext,
    Toolbar,
    TextInput,
    SelectInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    useRedirect,
    useRefresh,
    NumberInput,
    DateInput
} from 'react-admin';
import { Box, TextField, Button, Grid, Typography, Card, CardContent  } from '@mui/material';
import { AccountingFormInputs } from './AccountingFormInputs';
import { useNavigate } from 'react-router-dom';

const AccountingEditForm = () => {
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const ref = useRef();

    if (!identity) return null;

    return (
        <Box>
            <EditBase resource="accounting"
                redirect={false}
                mutationMode="optimistic"
                transform={async(data) => {
                    return ({
                        ...data,
                        date_time: new Date().toISOString(),
                        ap_id: localStorage.getItem('id'),
                        ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                    });
                }}
                mutationOptions={{
                    onSuccess: () => {
                        try{
                           notify('Информация о финансах сделки обновлена', { type: 'info', undoable: false });
                           navigate(-1);
                        }catch(err){console.log(err)}
                    }}
                }
            > 
            <Form>
            <AccountingFormInputs 
                ref={ref}
            />
            </Form>
            </EditBase>
        </Box>
    );
};

export default AccountingEditForm;