import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    EditButton,
    TopToolbar,
    CreateButton,
    Datagrid,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
    DateField,
    Link,
    ShowButton,
    FilterLiveSearch,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@mui/material';
import '../crm.css';
import { ColorField, ColorInput } from 'react-admin-color-picker';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';

const getIconByAction = (action) => {
    switch (action) {
        case 'Create':
            return <AddCircleIcon style={{ color: 'green' }} />;
        case 'Update':
            return <UpdateIcon style={{ color: '#FF8100' }} />;
        case 'Delete':
            return <DeleteIcon style={{ color: 'red' }} />;
        default:
            return null;
    }
};

const LogsListActions = () => (
    <TopToolbar>
        {/*<FilterLiveSearch />*/}
        {localStorage.getItem('role_id') < 2 && <ExportButton />}
    </TopToolbar>
);

const LogsListContent = () => {
    const {
        data: logs,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasSecondaryText />;
    }

    return(
                <List>
                {logs.map(log => (
                    <RecordContextProvider key={log.id} value={log}>
                        <ListItem
                            button
                            component={Link}
                            to={`/logs/${log.id}/show`}
                        >
                           {/* <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(log.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={e => {
                                        e.stopPropagation();
                                        onToggleItem(log.id);
                                    }}
                                />
                            </ListItemIcon> */}
                            <ListItemIcon>{getIconByAction(log.action)}</ListItemIcon>
                            <ListItemText
                                primary={log.history ? `${log.history}` : `${log.action} ${log.table}`}
                                secondary={new Date(log.date_time).toLocaleString()}
                            />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>    
    );
}

const LogsList = () => {
    const { identity } = useGetIdentity();

    return identity ? (
     <RaList  
        sx={{ marginTop: '0.5rem' }}
        actions={<LogsListActions />}
        perPage={10}
        filters={[<FilterLiveSearch alwaysOn />]}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} />}
        sort={{ field: 'date_time', order: 'DESC' }}>
          <LogsListContent /> 
       {/* <Datagrid>
          <TextField label="Действие" source="action" />
          <TextField label="Таблица" source="table" />
          <DateField label="Время" source="date_time" showTime locales="ru-RU" />
          <ShowButton />
        </Datagrid>*/}
     </RaList>     
   ) : null;
};

export default LogsList;