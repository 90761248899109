import React, { useState } from 'react';
import { usePopup } from '../design/PopupContext'; 
import MeetCreate from './MeetCreate';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const MeetCreatePopup = ({ initialRequest = null }) => {
    const { isOpen, closePopup, popupData } = usePopup();  // Access popup state from the context

    return (
        <Dialog open={isOpen} onClose={closePopup}
        fullWidth 
            PaperProps={{
                style: {
                    backgroundColor: 'transparent', 
                    boxShadow: 'none',
                    border: 'none', 
                    backgroundImage: 'none'
                },
            }}
            sx={{
                backdropFilter: 'blur(3px)',  // Apply blur effect to the backdrop
            }}
            >
           <MeetCreate initialRequest={popupData} />
        </Dialog>
    );
};

export default MeetCreatePopup;
