export const roles = [
    { id: 1, name: 'Администратор' },
    { id: 2, name: 'Директор' },
    { id: 3, name: 'Глава отдела' },
    { id: 4, name: 'Штатный менеджер' },
    { id: 5, name: 'Ко-воркинг менеджер' },
    { id: 6, name: 'Флиппинг менеджер' },

    { id: 15, name: 'РемонтПросто - менеджер' },
    { id: 16, name: 'ЛеманаПро - менеджер' },
    { id: 20, name: 'РемонтПросто - ходок' },
];