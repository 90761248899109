import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    Form,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton, Button  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';

export const WebsiteInputs = () => {
    const navigate = useNavigate();

    return (<Box flex="1" mt={-1}>

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
            <h3>Сайт</h3>
		</Box>

        <TextInput label="Название сайта (тег name)" source="name" fullWidth validate={required()} />

        <TextInput label="Описание сайта (тег description)" source="description" fullWidth multiline />

        <TextInput label="Ключевые слова (meta-keywords)" source="keywords" fullWidth multiline />

        <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
            <Box>
                <h3>Блоки</h3>
            </Box>
        <Box ml={5}>
            <Link to={`/webBlocks/create`}>
                <Button variant="contained" color="primary">
                + Новый блок
                </Button>
            </Link>
        </Box>
        </Box>


        <ArrayInput label="Блоки сайта" source="blocks">
        <SimpleFormIterator inline disableClear fullWidth>
            <ReferenceInput source="webBlock_id" reference="webBlocks" /*filter={{ 'sales_id_for_tasks': localStorage.getItem('id') }}*/ >
                <AutocompleteInput label="Блок сайта" optionText={(choice) => `${choice.name}`} fullWidth />
            </ReferenceInput>
        </SimpleFormIterator>
        </ArrayInput>


    </Box>);
};
