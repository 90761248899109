import React, { useState, useEffect } from 'react';
import { endpoint, wsEndpoint } from '../App';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch,
    FunctionField,
    Loading,
    DateInput,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    Form
} from 'react-admin';
import {
    Box, 
    useMediaQuery,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from 'axios';
import { AccountingFillForm } from './AccountingFillForm';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const filteredDataToExport = accounts.map(({ avatar, password, connectedToLeads, picture, ...rest }) => rest);

    const ws = XLSX.utils.json_to_sheet(filteredDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Аналитика - Выгрузка из CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

const formatNumberWithSpaces = (number) => {
    return new Intl.NumberFormat('en-US', { 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0 
    }).format(number).replace(/,/g, ' ');
};

const AccountingFilter = ({ setFilters, defaultStartDate, defaultEndDate, currentManagerFilter, handleSelectedManagerFilterChange }) => {
    const handleApplyFilters = (values) => {
        if (values.startDate && values.endDate) {
            setFilters({ search_start_time: values.startDate, search_end_time: values.endDate });
        }
    };

    return (
        <Form className='filterForm' onSubmit={handleApplyFilters}>
            <Box display="flex" flexDirection="row" mb={2} mt={2} alignItems="center">
            <DateInput
                    label="Начало периода"
                    source="startDate"
                    defaultValue={defaultStartDate}
                    style={{ marginRight: '1rem' }}
                />
                <DateInput
                    label="Окончание периода"
                    source="endDate"
                    defaultValue={defaultEndDate}
                    style={{ marginRight: '1rem' }}
                />
                    {/*localStorage.getItem('role_id') <= 3 &&
                    <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'head_of_dep': true }} >
                        <AutocompleteInput 
                            label="Руководитель" 
                            defaultValue={currentManagerFilter} 
                            onChange={handleSelectedManagerFilterChange} 
                            optionText={(choice) => `${choice.first_name} ${choice.last_name}`} 
                        />
                    </ReferenceInput>*/}
                    <Button type="submit" variant="contained" color="primary">
                        Показать
                    </Button>
            </Box>
        </Form>
    );
};

const DebitoryActions = () => {
    const { identity } = useGetIdentity();
    const navigate = useNavigate();
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        search_start_time: moment.utc().startOf('month').toDate(),
        search_end_time: moment.utc().endOf('month').toDate(),
    });
    const [currentManagerFilter, setCurrentManagerFilter] = useState(null);

    useEffect(() => {
        fetchData(filters);
    }, []);

    const fetchData = async (filters) => {
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            const id = localStorage.getItem('id');
            const response = await axios.get(
                `${endpoint}/debitoryPayments`,
                {
                    params: {
                        manager_account_id: id,
                        team_members_head_id: currentManagerFilter,
                        ...filters
                    },
                    headers: {
                        Authorization: `${token}`
                    }
                }
            );

            //const sortedPayments = sortByPaymentDate(response.data);
            //console.log(response.data);
            const sortedPayments = response.data;
            
            if(localStorage.getItem('role_id') > 3){
                // Менеджеры видят только себя
                const filteredPayments =  sortedPayments.filter(payment => payment.manager_account_id === id);
                setPayments(filteredPayments);
            }else{
                // Остальные видят всё

                setPayments(sortedPayments);
            }
        } catch (error) {
            console.error('Fetch failed:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSetFilters = (filters) => {
        fetchData(filters);
    };

    const handleSelectedManagerFilterChange = (value) => {
        setCurrentManagerFilter(value);
    };

    const totalPlan = payments.reduce((acc, payment) => acc + payment.income_plan, 0);
    const totalPaid = payments.reduce((acc, payment) => acc + payment.income_fact, 0);

    return identity ? (
        <Box>
            {localStorage.getItem('accountant') == 'true' && <AccountingFillForm />}
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="start">
            <Link to={`/debitory`}>
                <Button variant="contained" color="primary" style={{ marginRight: '1rem' }}>
                    Дебиторская задолженность
                </Button>
            </Link>
            <Link to={`/debitoryPayments`}>
                <Button variant="contained" color="primary" style={{ marginRight: '1rem' }}>
                    Список платежей
                </Button>
            </Link>
            <h3>Действия бухгалтерии</h3>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="start">
            <AccountingFilter 
                setFilters={handleSetFilters} 
                defaultStartDate={filters.search_start_time} 
                defaultEndDate={filters.search_end_time}
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
            />
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <List sx={{ backgroundColor: 'background.paper' }}>
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Добавлено/изменено" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Бухгалтер" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Дата операции" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Плановое поступление" secondary={`${formatNumberWithSpaces(totalPlan)} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Фактическое поступление" secondary={`${formatNumberWithSpaces(totalPaid)} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Клиент" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Сделка" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Менеджер" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Действия" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                    </ListItem>
            {payments.map(payment => {

                    return (
                        <ListItem 
                            key={payment.id} 
                            onClick={() => navigate(`/deals/${payment.deal.id}`)} 
                            onContextMenuCapture={ (event) => {
                                event.preventDefault();
                                window.open(`#/deals/${payment.deal.id}`, '_blank');
                            }}
                            sx={payment.deal.stage_id == 'cancelled' ? 
                                {backgroundColor:'#ff3838', color: 'white', padding: 0, alignItems: 'stretch', cursor: 'pointer'} 
                                : { padding: 0, alignItems: 'stretch', cursor: 'pointer'}
                            } 
                        >
                            
                            {/*  Добавлено/изменено */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey'  
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${new Date(payment.date_time).toLocaleString('ru-RU')}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                            </Box>

                            {/* Бухгалтер */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${payment.ap_name}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight:'normal' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/*  Дата операции */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey'  
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${formatDateString(payment.date)}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                        secondary={payment.comment && `${payment.comment}`}
                                        secondaryTypographyProps={payment.deal.stage_id == 'cancelled' && { 
                                            sx:{ color: 'white' }
                                        }}
                                    />
                                </Box>

                            </Box>

                            {/*  Плановое поступление */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey'  
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${formatNumberWithSpaces(payment.income_plan)} ₽`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                            </Box>

                            {/*  Фактическое поступление */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '10rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey'  
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center"  sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${formatNumberWithSpaces(payment.income_fact)} ₽`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight: 'bold' }
                                        }}
                                    />
                                </Box>

                            </Box>
                            
                            {/*  Клиент */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${payment.deal.name}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight:'normal' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/*  Сделка */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                                onClick={() => navigate(`/deals/${payment.deal.id}`)}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`№${payment.deal.document_name}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight:'normal', textDecoration: 'underline' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/* Менеджер */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey' 
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                                    <ListItemText 
                                        primary={`${payment.deal.manager_name}`} 
                                        primaryTypographyProps={{ 
                                            sx:{ fontWeight:'normal' }
                                        }}
                                        secondary={payment.deal.stage_id == 'cancelled' && `(Сделка отменена)`}
                                        secondaryTypographyProps={{ 
                                            sx:{ fontWeight:'normal', color: 'white' }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {/* Кнопка "Редактировать" */}  
                            <Box 
                                display="flex" 
                                flex={1} 
                                justifyContent="start" 
                                alignItems="center" 
                                sx={{ 
                                    minWidth: '7rem', 
                                    borderRight: '1px solid lightgrey',
                                    borderBottom: '1px solid lightgrey',
                                }}
                            >
                                <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem', paddingY: '0.2rem' }}>
                                   <EditButton resource="accounting" record={payment} sx={payment.deal.stage_id == 'cancelled' && {color: 'white'}} />
                                </Box>
                            </Box>

                        </ListItem>
                    );
                })}
                    <ListItem sx={{ padding: 0, alignItems: 'stretch', borderBottom: '1px solid lightgrey' }}>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Добавлено/изменено" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Бухгалтер" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Дата" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText secondary="Плановое поступление" primary={`${formatNumberWithSpaces(totalPlan)} ₽`} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '10rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary={`${formatNumberWithSpaces(totalPaid)} ₽`} secondary="Фактически поступило" sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} />
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Клиент" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Сделка" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Менеджер" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{ minWidth: '7rem', borderRight: '1px solid lightgrey' }}>
                            <ListItemText primary="Действия" secondary={``} sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}/>
                        </Box>
                    </ListItem>
                </List>
            )}
        </Box>
    ) : (
        <Loading />
    );
};

const ListActions = () => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

    </TopToolbar>
);

export default DebitoryActions;
