import * as React from 'react';
import { useState } from 'react';
import {
    useRecordContext,
    useListContext,
    useCreate,
    useUpdate,
    useNotify,
    useGetIdentity,
    Identifier,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    CreateBase,
    SaveButton,
    SimpleForm,
    useEditContext,
    Toolbar,
    TextInput,
    SelectInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    TextField,
    useDataProvider,
    useRedirect,
    useRefresh,
    NumberInput,
    DateInput,
    DateField,
    TimeInput
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { formats } from './formats';
import { statuses } from './statuses';

export const MeetInputs = ({ onRequestChange, initialRequest = null }) => {
    const dataProvider = useDataProvider();
    const editContext = useEditContext();
    const navigate = useNavigate();

    const handleRequestChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('requests', { id });
			//setSelectedManager(recordFromDatabase.data);
			onRequestChange(recordFromDatabase.data);
		}else{
			//setSelectedManager({});
		}
	};

    return (<Box flex="1" mt={-1}>

        { !editContext.record ? 
            <h3>Назначьте встречу</h3>
            :
            <Box display="flex" flexDirection="row">
                <IconButton
                    onClick={() => navigate(-1)}
                    size="small"
                    >
                    <KeyboardBackspaceIcon />
                </IconButton>
                <h3>Встреча с клиентом</h3>
            </Box>
        }

        { !editContext.record ? 
                        <ReferenceInput source="request_id" reference="requests" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                            <AutocompleteInput defaultValue={initialRequest ? initialRequest.id : null} onChange={handleRequestChange} isLoading={true} label="Клиент (лид) (введите часть имени и подождите 5 секунд)" filterToQuery={search => ({ "q": search })}  optionText={(choice) => `${choice.name} ${choice.phone} - ${choice.manager_name}`} fullWidth validate={required()} />
                        </ReferenceInput> : 
                        <Box>
                        <DateField source="date_time" mr={1} />
                        <TimeInput label="Время встречи" source="date_time" validate={required()} />
                        <ReferenceField source="request_id" reference="requests" >
                            <TextField source="name" ml={1}/>
                            <TextField source="phone" ml={1} mr={1}/>
                            -
                            <TextField source="manager_name" ml={1}/>
                        </ReferenceField>
                        </Box>
                        }

        <Grid container spacing={2} >
                    <Grid item xs={12} sm={6}>
                    <SelectInput
                    label="Формат встречи"
                    source="format"
                    choices={formats}
                    validate={required()}
                    fullWidth 
                    />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <SelectInput
                    label="Статус встречи"
                    source="status"
                    choices={statuses}
                    defaultValue={'scheduled'}
                    validate={required()}
                    fullWidth 
                    />
                    </Grid>
        </Grid>

        { !editContext.record && <DateTimeInput label="Дата и время встречи" source="date_time" /*defaultValue={new Date()}*/ validate={required()} sx={{ marginTop: 1 }}/> }

    </Box>);
};
