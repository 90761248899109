import {
    defaultTheme, 
    nanoLightTheme, 
    nanoDarkTheme,
    radiantLightTheme, 
    radiantDarkTheme,
    houseLightTheme, 
    houseDarkTheme,
  } from 'react-admin';
import { createTheme } from '@mui/material/styles';

export const themes = [
    {id: 0, name: "Стандартная", lightTheme: defaultTheme, darkTheme: { ...defaultTheme, palette: { mode: 'dark' } }},
    {id: 1, name: "Минимализм", lightTheme: nanoLightTheme, darkTheme: nanoDarkTheme},
    {id: 2, name: "Современная", lightTheme: radiantLightTheme, darkTheme: radiantDarkTheme},
    {id: 3, name: "Яркая", lightTheme: houseLightTheme, darkTheme: houseDarkTheme}
]

const baseTheme = {
  typography: {
      fontFamily: [
        '"Poppins"', // Modern font
        'Arial', 
        'sans-serif'
      ].join(','),
      h1: {
          fontWeight: 600,
          fontSize: '2.5rem',
          lineHeight: 1.2,
      },
      h2: {
          fontWeight: 600,
          fontSize: '2rem',
          lineHeight: 1.3,
      },
      h3: {
          fontWeight: 600,
          fontSize: '1.75rem',
          lineHeight: 1.4,
      },
      h4: {
          fontWeight: 500,
          fontSize: '1.5rem',
          lineHeight: 1.5,
      },
      h5: {
          fontWeight: 500,
          fontSize: '1.25rem',
          lineHeight: 1.6,
      },
      h6: {
          fontWeight: 500,
          fontSize: '1rem',
          lineHeight: 1.7,
      },
      body1: {
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: 1.5,
      },
      body2: {
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: 1.4,
      },
      button: {
          textTransform: 'none',
      },
  },
  shape: {
      borderRadius: 8,
  },
  components: {
      MuiButton: {
          styleOverrides: {
              root: {
                  borderRadius: 8,
                  textTransform: 'none',
                  padding: '8px 16px',
              },
          },
      },
      MuiCard: {
          styleOverrides: {
              root: {
                  borderRadius: 12,
              },
          },
      },
      MuiTextField: {
          styleOverrides: {
              root: {
                  borderRadius: 8,
              },
          },
      },
  },
};

// Light Theme with logotype color
const lightTheme = createTheme({
  ...baseTheme,
  palette: {
      mode: 'light',
      primary: {
          main: '#0092C8', // Logotype color
          light: '#0092C8', // Lighter shade for hover/active states
          dark: '#0092C8',  // Darker shade for contrast
      },
      secondary: {
          main: '#FF8100', // Adjust this to match with logotype color
          light: '#FFA500',
          dark: '#CC6600',
      },
      error: {
          main: '#FF0000',
      },
      background: {
          default: '#F9F9F9',// бежевый - FFF8DE, голубой - B3E5FC, сероватый - F9F9F9, слоновая кость - fffff0, красноватый - FFEBE4, мягкий коралловый - fff0eb
          paper: '#FFFFFF',
      },
      text: {
          primary: '#000000',
          secondary: '#333333',
      },
  },
});

// Dark Theme with logotype color
const darkTheme = createTheme({
  ...baseTheme,
  palette: {
      mode: 'dark',
      primary: {
          main: '#00C8FF', // Logotype color
          light: '#33D9FF', // Lighter shade for hover/active states
          dark: '#0092C8',  // Darker shade for contrast
      },
      secondary: {
          main: '#FF8100', // Adjust this to match with logotype color
          light: '#FFB84D',
          dark: '#CC6600',
      },
      error: {
          main: '#FF0000',
      },
      background: {
          default: '#121212',
          paper: '#1D1D1D',
      },
      text: {
          primary: '#FFFFFF',
          secondary: '#B3B3B3',
      },
  },
});

export { lightTheme, darkTheme };