import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch,
    FunctionField
} from 'react-admin';
import {
    Box, 
    useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const filteredDataToExport = accounts.map(({ avatar, password, connectedToLeads, picture, ...rest }) => rest);

    const ws = XLSX.utils.json_to_sheet(filteredDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Пользователи - Выгрузка из CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay(); // Day of the week (0-6), where 0 is Sunday and 6 is Saturday
    const mondayDate = new Date(currentDate); // Copy the current date
    mondayDate.setDate(currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust to Monday

    const dates = [];
    for (let i = 0; i < 7; i++) {
        const date = new Date(mondayDate);
        date.setDate(mondayDate.getDate() + i);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        dates.push(`${day}.${month}`);
    }
    return dates;
};

const WorkShiftList = () => {
    const { identity } = useGetIdentity();
    const currentWeekDates = getCurrentWeekDates();

    return identity ? (
        <RaList
            sx={{ marginTop: '1rem' }}
            resource='sales'
            actions={localStorage.getItem('role_id') <= 2 ? <AccountListActions /> : null}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            filters={[<FilterLiveSearch alwaysOn />]}
        >
            <Datagrid rowClick={localStorage.getItem('role_id') <= 2 ? "edit" : false} bulkActionButtons={false}>
                <FunctionField source="first_name" label="Пользователь" render={record => `${record.first_name} ${record.last_name}`}/>
                <BooleanField label="Подключен к лидам" source="connectedToLeads" />
                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day, index) => {
                    const dayLabels = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
                    return (
                        <FunctionField
                            key={day}
                            label={`${dayLabels[index]} ${currentWeekDates[index]}`}
                            render={(record) =>
                                record[day] && record[`${day}_start_time`] && record[`${day}_end_time`]
                                    ? `${formatTime(record[`${day}_start_time`])} - ${formatTime(record[`${day}_end_time`])}`
                                    : '-'
                            }
                        />
                    );
                })}
            </Datagrid>
        </RaList>
    ) : null;
};

const AccountListActions = () => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Link to={`/sales`}>
            <Button variant="contained" color="primary">
              Список пользователей
            </Button>
        </Link>
        {/*localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null*/}
        <CreateButton
            variant="outlined"
            label="Новый пользователь"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

export default WorkShiftList;
