import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';

const TaskTypeListContent = () => {
    const {
        data: taskTypes,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
           {/*<BulkActionsToolbar>
                
                <BulkDeleteButton />
            </BulkActionsToolbar>*/}
            <List>
                {taskTypes.map(taskType => (
                    <RecordContextProvider key={taskType.id} value={taskType}>
                        <ListItem
                            button
                            component={Link}
                            to={`/taskTypes/${taskType.id}`}
                        >
                            <ListItemText
                                primary={taskType.name}
                            />
                            <EditButton />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const TaskTypeListActions = () => (
    <TopToolbar>
        <CreateButton
            variant="contained"
            label="Новый тип задачи"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const TaskTypeList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            sx={{ marginTop: '1rem' }}
            actions={localStorage.getItem('role_id') <= 2 ? <TaskTypeListActions /> : null}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
        >
            <TaskTypeListContent />
        </RaList>
    ) : null;
};

export default TaskTypeList;


