import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    TimeInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { Divider, Box, Grid, Typography  } from '@mui/material';
import { roles } from './roles';

export const AccountInputs = ({link, username}) => {
    const record = useRecordContext();

    return (<Box flex="1" mt={-1}>
        {!username || localStorage.getItem('role_id') < 2 || (localStorage.getItem('role_id') <= 2 && localStorage.getItem('id') !== record.id) ? <TextInput label="Имя пользователя (латиницей)" source="username" fullWidth validate={required()}/> : null}
        {link !== "edit" ? <TextInput label="Пароль" source="password" fullWidth validate={required()}/> : null}
        {link == "edit" && (localStorage.getItem('role_id') < 2 || localStorage.getItem('id') === record.id) ?
        <Box flex="1" flexDirection="column">
        <TextInput label="Старый пароль (вводить только если хотите сменить пароль)" source="prev_password" fullWidth /> 
        <TextInput label="Новый пароль (вводить только если хотите сменить пароль)" source="new_password" fullWidth /> 
        </Box>
        : null}

        <h3>Основные данные</h3>

         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Имя" source="first_name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Фамилия и/или Отчество" source="last_name" fullWidth validate={required()} />
            </Grid>
        </Grid>

        <ImageInput source="picture" label="Аватар" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>

        <Divider />

        <h3>Контактные данные</h3>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Email" source="email" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Telegram @username" source="telegram_username" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона" source="phone" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Добавочный номер" source="phone_extension" fullWidth />
            </Grid>
        </Grid>

        <Divider />
        
        <h3>Должностные данные</h3>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <SelectInput
                label="Роль в системе"
                source="role"
                choices={localStorage.getItem('role_id') < 2 ? roles : roles.slice(1)}
                validate={required()} 
                fullWidth 
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Название должности" source="position" fullWidth validate={required()} />
            </Grid>
        </Grid>

        <BooleanInput label="Пользователь является Руководителем отдела (РОПом)" source="head_of_dep" />
        <BooleanInput label="Пользователь является Бухгалтером" source="accountant" />

        <h3>Основная воронка (по какому направлению будут распределяться лиды для этого менеджера):</h3>

        <ReferenceInput source="funnel_id" reference="funnels" >
            <SelectInput
                label="Выберите воронку"
                optionText={(choice) => `${choice.name}`}
                disableCloseOnSelect={true}
                blurOnSelect={false}
                debounce={500}
                fullWidth 
                validate={required()}
            />
        </ReferenceInput>


        <h3>Команда (если пользователь является РОПом, для разделов бухгалтерии):</h3>

        <ReferenceArrayInput source="team_members_ids" reference="sales" filter={username ? { exclude_id: [record.id]} : null}>
            <AutocompleteArrayInput
                label="Выберите 1 или несколько аккаунтов"
                optionText={(choice) => `${choice.first_name} ${choice.last_name}`}
                disableCloseOnSelect={true}
                blurOnSelect={false}
                debounce={500}
            />
        </ReferenceArrayInput>

        <h3>Чьи лиды и сделки видит этот аккаунт кроме своих и кому может давать их:</h3>

        <ReferenceArrayInput source="subordinates_ids" reference="sales" filter={username ? { exclude_id: [record.id]} : null}>
            <AutocompleteArrayInput
                label="Выберите 1 или несколько аккаунтов"
                optionText={(choice) => `${choice.first_name} ${choice.last_name}`}
                disableCloseOnSelect={true}
                blurOnSelect={false}
                debounce={500}
            />
        </ReferenceArrayInput>

        <h3>Чьи задачи видит этот аккаунт кроме своих и кому может давать их:</h3>

        <ReferenceArrayInput source="team_tasks_ids" reference="sales" filter={username ? { exclude_id: [record.id]} : null}>
            <AutocompleteArrayInput
                label="Выберите 1 или несколько аккаунтов"
                optionText={(choice) => `${choice.first_name} ${choice.last_name}`}
                disableCloseOnSelect={true}
                blurOnSelect={false}
                debounce={500}
            />
        </ReferenceArrayInput>

        <h3>График работы (автовключение-отключение лидов)</h3>
        <Typography variant="body2" color='secondary' >* Заполнять если работает платная реклама и клиенты оставляют где-то заявки на звонок и тд</Typography>

        <BooleanInput label="Подключен к лидам (главный переключатель)" source="connectedToLeads" validate={required()} sx={{marginTop: '1rem'}}/>

        <h5>Понедельник</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="monday" />
        <TimeInput label="Начало рабочего дня" source="monday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="monday_end_time" sx={{width: '10rem'}} />

        <h5>Вторник</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="tuesday" />
        <TimeInput label="Начало рабочего дня" source="tuesday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="tuesday_end_time" sx={{width: '10rem'}} />

        <h5>Среда</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="wednesday" />
        <TimeInput label="Начало рабочего дня" source="wednesday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="wednesday_end_time" sx={{width: '10rem'}} />

        <h5>Четверг</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="thursday" />
        <TimeInput label="Начало рабочего дня" source="thursday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="thursday_end_time" sx={{width: '10rem'}} />

        <h5>Пятница</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="friday" />
        <TimeInput label="Начало рабочего дня" source="friday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="friday_end_time" sx={{width: '10rem'}} />

        <h5>Суббота</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="saturday" />
        <TimeInput label="Начало рабочего дня" source="saturday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="saturday_end_time" sx={{width: '10rem'}} />

        <h5>Воскресенье</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="sunday" />
        <TimeInput label="Начало рабочего дня" source="sunday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="sunday_end_time" sx={{width: '10rem'}} />

        <ArrayInput label="Рабочие недели" source="weeks">
        <SimpleFormIterator inline disableClear disableReordering getItemLabel={index => `Неделя №${index + 1}`} >
        
        <Box>
        <h5>Понедельник</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="monday" />
        <TimeInput label="Начало рабочего дня" source="monday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="monday_end_time" sx={{width: '10rem'}} />

        <h5>Вторник</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="tuesday" />
        <TimeInput label="Начало рабочего дня" source="tuesday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="tuesday_end_time" sx={{width: '10rem'}} />

        <h5>Среда</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="wednesday" />
        <TimeInput label="Начало рабочего дня" source="wednesday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="wednesday_end_time" sx={{width: '10rem'}} />

        <h5>Четверг</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="thursday" />
        <TimeInput label="Начало рабочего дня" source="thursday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="thursday_end_time" sx={{width: '10rem'}} />

        <h5>Пятница</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="friday" />
        <TimeInput label="Начало рабочего дня" source="friday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="friday_end_time" sx={{width: '10rem'}} />

        <h5>Суббота</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="saturday" />
        <TimeInput label="Начало рабочего дня" source="saturday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="saturday_end_time" sx={{width: '10rem'}} />

        <h5>Воскресенье</h5> 
        <BooleanInput label="Пользователь работает в этот день" source="sunday" />
        <TimeInput label="Начало рабочего дня" source="sunday_start_time" sx={{marginRight: '1rem', width: '10rem'}} />
        <TimeInput label="Конец рабочего дня" source="sunday_end_time" sx={{width: '10rem'}} />

        </Box>

        </SimpleFormIterator>
        </ArrayInput>

        {/*<TextInput source="avatar" fullWidth />
          <ImageInput source="avatar" label="Related pictures">
            <ImageField source="src" title="title" />
        </ImageInput>*/}

    </Box>);
};

const Spacer = () => <Box width={20} component="span" />;
