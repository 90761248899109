import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
	EditButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';

const FunnelListContent = () => {
    const {
        data: funnels,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasSecondaryText />;
    }

    return (
        <>
            <BulkActionsToolbar>
                <BulkDeleteButton />
            </BulkActionsToolbar>
            <List>
                {funnels.map(funnel => (
                    <RecordContextProvider key={funnel.id} value={funnel}>
                        <ListItem
                            button
                            component={Link}
                            to={`/funnels/${funnel.id}`}    
                        >
                            <ListItemText
                                primary={funnel.name}
                                secondary={funnel.description}
                            />
							<EditButton />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const FunnelListActions = () => (
    <TopToolbar>
        <CreateButton
            variant="contained"
            label="Новая воронка"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const FunnelList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            sx={{ marginTop: '1rem' }}
            actions={<FunnelListActions />}
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        >
            <FunnelListContent />
        </RaList>
    ) : null;
};

export default FunnelList;
