import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    SelectInput,
    TopToolbar,
    useGetIdentity,
    useRefresh,
    useGetList,
    Form,
    required,
    useListContext,
    RecordContextProvider,
    EditButton,
    Pagination,
    SimpleListLoading,
    ReferenceField,
    TextField,
    SimpleList,
    Loading,
    InfiniteList,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    useRedirect,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import { List as MUIList,ListItem, ListItemText, Box, Dialog, DialogContent, Typography, Divider, Link as MUILink, IconButton, useMediaQuery} from '@mui/material';
import { DealListContent } from './DealListContent';
import { RequestCreate } from './RequestCreate';
import { RequestEdit } from './RequestEdit';
import { OnlyMineInput } from './OnlyMineInput';
import { typeChoices, getStatusColor } from './types';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { CallButton} from '../calls/CallButton';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import TelegramIcon from '@mui/icons-material/Telegram';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: requests,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    /*const fieldOrder = [
      'number',
      'name',
      'manager_name',
      'status',
      'id',
      'funnel_id',
      'stage'
    ];*/

  /*const reorderedDataToExport = requests.map((request) => {
    const reorderedAccount = { };
     fieldOrder.forEach((field) => {
         reorderedAccount[field] = Array.isArray(request[field]) ? request[field].join(', ') : request[field];
     });

    // Flatten the rest of the object
    Object.keys(reorderedAccount).forEach((field) => {
        if (Array.isArray(reorderedAccount[field])) {
            reorderedAccount[field] = reorderedAccount[field].join(', ');
        }
    });

    return reorderedAccount;
});*/

    const ws = XLSX.utils.json_to_sheet(requests);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Заявки - Выгрузка из CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />} sx={{ marginLeft: 2 }}>
      Выгрузить в Excel
    </Button>
  );
};

const RequestList = () => {
    const { identity } = useGetIdentity();
    const location = useLocation();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [isLoading, setLoading] = useState(false);
    const [currentFunnel, setCurrentFunnel] = useState(/*{"id": "123", "name": "fallback", "stages": []}*/);
    const [currentManagerFilter, setCurrentManagerFilter] = useState();
    const [currentStageFilter, setCurrentStageFilter] = useState();
    var filtersObject = {manager_account_id: '', stage: ''};
    const views = [{id: 1, name: 'Канбан'}, {id: 2, name: 'Список'}];
    const [currentView, setCurrentView] = useState(views[0]);
    const listContentRef = useRef();
    const filters = [
        {id: 1, name: 'Обычное', order: 'DESC', field: 'updated_at'}, 
        {id: 2, name: 'С конца', order: 'ASC', field: 'updated_at'},
    ];
    const [currentFilter, setCurrentFilter] = useState(filters[0]);

    const handleFilterChange = (value) => {
        setCurrentFilter(filters.find((item) => item.id === value.target.value));
        if (listContentRef.current) {
            listContentRef.current.setSort1(filters.find((item) => item.id === value.target.value).field, filters.find((item) => item.id === value.target.value).order);
        }
    };

    const handleSelectedStageChange = (event) => {
        setCurrentStageFilter(event.target.value);
        localStorage.setItem('currentStageFilter', event.target.value);
        filtersObject.stage = event.target.value;
        filtersObject.manager_account_id = currentManagerFilter;
        if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };

    const handleDropAllFilters = () => {
        setCurrentManagerFilter("");
        setCurrentStageFilter("");
        filtersObject.manager_account_id = "";
        filtersObject.stage = "";
        if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
        localStorage.setItem('currentManagerFilter', "");
        localStorage.setItem('currentStageFilter', "");

        setLoading(true);

        //refresh();
        redirect( `/requests`);
        //refresh();
        setTimeout(() => {
            window.location.reload();
        }, 3000);

          // Redirect to the desired URL and then reload the page
        //window.location.href = '/requests';
        //window.location.reload(); // This will reload the page after the redirect
    }

    const handleClose = () => {
        refresh();
    };

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'name', order: 'ASC' },
        }
    );

    /*useEffect(() => {
      const storedViewId = localStorage.getItem('currentViewId');
      if(storedViewId){
        setCurrentView(views[storedViewId-1]);
      }
      const storedFunnelId = localStorage.getItem('currentFunnelId');
      if (funnels && storedFunnelId) {
        const selectedFunnel = funnels.find((item) => item.id === storedFunnelId);
        setCurrentFunnel(selectedFunnel || funnels[0]);
      } else if (funnels && funnels.length > 0) {
        setCurrentFunnel(funnels[0]);
      }
    }, [funnels]);*/

    useEffect(() => {
      if (currentFunnel) {
        localStorage.setItem('currentFunnelId', currentFunnel.id);
      }
    }, [currentFunnel]);
    useEffect(() => {
      const storedViewId = localStorage.getItem('currentViewId');
      if(storedViewId){
        setCurrentView(views[storedViewId-1]);
      }
    }, [currentView]);

    useEffect(() => {
        const storedManagerFilter= localStorage.getItem('currentManagerFilter');
        if(storedManagerFilter){
            setCurrentManagerFilter(storedManagerFilter);
            filtersObject.manager_account_id = storedManagerFilter;
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
        if(storedManagerFilter == ""){
            setCurrentManagerFilter("");
            filtersObject.manager_account_id = "";
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
    }, [currentManagerFilter]);

    useEffect(() => {
        const storedStageFilter= localStorage.getItem('currentStageFilter');
        if(storedStageFilter){
            setCurrentStageFilter(storedStageFilter);
            filtersObject.stage = storedStageFilter;
            filtersObject.manager_account_id = currentManagerFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
        if(storedStageFilter == ""){
            setCurrentStageFilter("");
            filtersObject.stage = "";
            filtersObject.manager_account_id = currentManagerFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
    }, [currentStageFilter]);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const funnel_id = queryParams.get('funnel_id');
        if(funnels && funnel_id){
            setCurrentFunnel(funnels.find((item) => item.id === funnel_id));
        }
        if(funnels && !funnel_id){
            const storedViewId = localStorage.getItem('currentViewId');
            if(storedViewId){
              setCurrentView(views[storedViewId-1]);
            }
            const storedFunnelId = localStorage.getItem('currentFunnelId');
            if (funnels && storedFunnelId) {
              const selectedFunnel = funnels.find((item) => item.id === storedFunnelId);
              setCurrentFunnel(selectedFunnel || funnels[0]);
            } else if (funnels && funnels.length > 0) {
              setCurrentFunnel(funnels[0]);
            }
        }
    }, [funnels, location.search]);

    if(isLoadingFunnels || !currentFunnel || !currentFunnel.stages || !identity || isLoading) return <Loading />;

    const handleSelectChange = (value) => {
         console.log('Selected value:', value.target.value);
         setCurrentFunnel(funnels.find((item) => item.id === value.target.value));
    };

    const handleViewChange = (value) => {
         console.log('Selected value:', value);
         setCurrentView(views.find((item) => item.id === value));
         localStorage.setItem('currentViewId', value);
         if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };

    const handleSelectedManagerFilterChange = (value) => {
        //setCurrentFilter(filters.find((item) => item.id === value.target.value));
        setCurrentManagerFilter(value);
        localStorage.setItem('currentManagerFilter', value);
        filtersObject.manager_account_id = value;
        filtersObject.stage = currentStageFilter;
        if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };
/*
лиды без задач
лиды с просроченными от самой старой по времени
новые
 */
    return (
        <>
           { !isSmall && currentView.id === 1 && identity ?
            <List
                sx={{ marginTop: '1rem' }}
                perPage={500}
                //sort={{ field: 'index', order: 'ASC' }}
                sort={{ field: 'updated_at', order: 'DESC' }}
                filter={{'funnel_id': currentFunnel.id, 'sales_id_for_requests': localStorage.getItem('id')}} 
                filters={/*localStorage.getItem('role_id') <= 3 ? requestsFilters :*/ requestsFiltersForManagers}
                //filterDefaultValues={ localStorage.getItem('role_id') === 4 ? { sales_id: identity && identity?.id } : null} // когда это закомментировано тумблер "только мои сделки" по дефолту отключен
                actions={<RequestsActions 
                    handleViewChange={handleViewChange} 
                    isSmall={isSmall} 
                    handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                    currentManagerFilter={currentManagerFilter} 
                    filters={filters} 
                    currentFilter={currentFilter} 
                    handleFilterChange={handleFilterChange}
                    currentFunnel={currentFunnel}
                    onSelectedStageChange={handleSelectedStageChange}
                    currentView={currentView.id}
                    dropAllFilters={handleDropAllFilters}
                    />}
                //pagination={false}
                //pagination={<Pagination rowsPerPageOptions={[100, 500, 1000]} />}
                component="div"
            >
            {/*<Form>
                <Box display="flex" flexDirection="row" >
                <SelectInput label="Отображение" source="view" choices={views} defaultValue={currentView.id} onChange={handleViewChange} validate={required()} />
                <Box width={30} component="span" />
                <SelectInput label="Воронка" source="funnel" choices={funnels} defaultValue={currentFunnel.id} onChange={handleSelectChange} validate={required()} />
                </Box>
            </Form>*/}
                <div style={{ marginBottom: '2rem' }}>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <Pagination rowsPerPageOptions={[100, 500, 1000]} />
                </div>
                </div>
                <DealListContent funnel={currentFunnel} stages={currentFunnel.stages} ref={listContentRef}/>
            </List> : null}
            
            { isSmall || currentView.id === 2 ? 
            <Box display="flex" flexDirection="column" >
            <List
            sx={{ marginTop: '1rem' }}
            actions={<RequestsActions 
                handleViewChange={handleViewChange} 
                isSmall={isSmall} 
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
                filters={filters} 
                currentFilter={currentFilter} 
                handleFilterChange={handleFilterChange}
                currentFunnel={currentFunnel}
                onSelectedStageChange={handleSelectedStageChange}
                currentView={currentView.id}
                dropAllFilters={handleDropAllFilters}
                />}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            filter={{'funnel_id': currentFunnel.id, 'sales_id_for_requests': localStorage.getItem('id')}} 
            filters={/*localStorage.getItem('role_id') <= 3 ? requestsFilters :*/ requestsFiltersForManagers}
            sort={{ field: 'updated_at', order: 'DESC' }}
            >
               {/*<Form>
                    <Box display="flex" flexDirection="row" >
                    <SelectInput label="Отображение" source="view" choices={views} defaultValue={currentView.id} onChange={handleViewChange} validate={required()} />
                    <Box width={30} component="span" />
                    <SelectInput label="Воронка" source="funnel" choices={funnels} defaultValue={currentFunnel.id} onChange={handleSelectChange} validate={required()} />
                    </Box>
               </Form>*/}
                <div style={{ marginBottom: '2rem' }}>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />
                </div>
                </div>
                <RequestListContent funnels={funnels} funnelId={currentFunnel.id} ref={listContentRef} />
            </List></Box>  : null}
        </>
    );
};

const requestsFilters = [
    <SearchInput source="q" alwaysOn />,
    <OnlyMineInput alwaysOn />,
    <SelectInput source="status" choices={typeChoices} />,
];
const requestsFiltersForManagers = [
     <SearchInput source="q" alwaysOn />,
     <SelectInput source="status" choices={typeChoices} />,
];

const RequestsActions = ({handleViewChange, isSmall, handleSelectedManagerFilterChange, currentManagerFilter, filters, currentFilter, handleFilterChange, currentFunnel, onSelectedStageChange, currentView, dropAllFilters }) => {
    return (
        <TopToolbar sx={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row', justifyContent: 'center', alignItems: 'center' }}>
            {/*localStorage.getItem('role_id') <= 2 ? <FilterButton /> : null*/}

            {!isSmall &&
            <IconButton
                color="warning"
                onClick={() => {
                    dropAllFilters();
                }}
            ><SyncProblemIcon />
            </IconButton>}
         
            {!isSmall &&
            <IconButton
                color="primary"
                onClick={() => {
                    handleViewChange(1);
                }}
            ><ViewWeekIcon />
            </IconButton>}
            {!isSmall &&
            <IconButton
                color="primary"
                onClick={() => {
                    handleViewChange(2);
                }}
            ><TableRowsIcon />
            </IconButton>}

            {!isSmall &&
            <Form className='filterForm'>
            <SelectInput label="Отображение" source="view" choices={filters} defaultValue={currentFilter.id} onChange={handleFilterChange} validate={required()} helperText={null} />
            </Form>}

            
            <Form className="tasks-views">
              <SelectInput choices={currentFunnel.stages} optionText="name" optionValue="id" source="stage" label="Этап" onChange={onSelectedStageChange}/>
            </Form>

            {localStorage.getItem('role_id') <= 3 ?
            <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
            </Form> : null}

            {localStorage.getItem('role_id') < 2 && !isSmall ? <CustomExportButton /> : null}
            <CreateButton
                variant="outlined"
                label="Новая заявка"
                sx={{ marginLeft: 2 }}
            />
        </TopToolbar>
    );
};

const RequestListContent = forwardRef(( props, ref) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        data: requests,
        isLoading, isFetching,
        onToggleItem,
        selectedIds,
        setFilters,
        setSort
    } = useListContext();

    const setListFilter = (filtersObject) => {
        setFilters(filtersObject, false);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (filtersObject) => setListFilter(filtersObject),
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));

    const setSort1 = (field1, order1) => {
        setSort({ field: field1, order: order1 });
    };

    if (isLoading || isFetching) {
        return <Loading />
    }
    /*if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }*/
    const now = Date.now();

    /*const filteredRequests = isSmall ? requests : requests.filter(request => request.funnel_id === funnelId);

    filteredRequests.sort((a, b) => {
        // First condition: Sort by request.task_time null or "", then by created_at
        if (!a.task_time || a.task_time === "") {
            if (!b.task_time || b.task_time === "") {
                // If both task_time are null or "", sort by created_at in ascending order
                return new Date(a.created_at) - new Date(b.created_at);
            }
            return -1; // a comes before b
        }
        if (!b.task_time || b.task_time === "") {
            return 1; // b comes before a
        }
    
        const taskTimeA = new Date(a.task_time);
        const taskTimeB = new Date(b.task_time);
    
        // Second condition: Sort by task_time before current time
        if (taskTimeA < now) {
            if (taskTimeB < now) {
                // Both task_time are before current time, sort by task_time in ascending order
                return taskTimeA - taskTimeB;
            }
            return -1; // a comes before b
        }
        if (taskTimeB < now) {
            return 1; // b comes before a
        }
    
        // Third condition: Sort by task_time after current time
        return taskTimeA - taskTimeB; // Sort by task_time in ascending order
    });*/

    return (
        <>
           {/*<BulkActionsToolbar>
                
                <BulkDeleteButton />
            </BulkActionsToolbar>*/}
             {isSmall ? 
                         <SimpleList
                            primaryText={request => `${request.name} ${request.realty_id ? request.realty_title : ''}`}
                            secondaryText={request => request.task_time && request.task_time !== '' ? `${new Date(request.task_time).toLocaleString('ru-RU')}, ${request.manager_name}` : `${request.manager_name}`}
                            linkType={"edit"}   
                            rowSx={request => ({
                                borderLeft: request.status ? `4px solid ${getStatusColor(request.status)}` : null,
                                padding: '0 16px',
                                marginRight: '16px', 
                                border: (request.task_time && new Date(request.task_time) < new Date()) || !request.task_time || (request.task_time && request.task_time == "") ? '4px solid red' : 'none',
                                animation: (request.task_time && new Date(request.task_time) < new Date()) || !request.task_time || (request.task_time && request.task_time == "") ? 'blinkingBorder 1s infinite' : 'none',
                            })}
                         /> :
            <MUIList>
                {requests.map(request => { 
                    // Check if task_time is before current time
                    var inactive_stage = props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.request_done;
                    var isTaskTimeBeforeCurrentTime = (request.task_time && new Date(request.task_time) < new Date()) || !request.task_time || (request.task_time && request.task_time == "");
                    if(inactive_stage == true){
                        isTaskTimeBeforeCurrentTime = false;
                    }
                    function formatTaskTime() {
                        if (request.task_time && request.task_time !== '' && !inactive_stage) {
                            return new Date(request.task_time).toLocaleString('ru-RU');
                        } else if (!inactive_stage) {
                            return 'Поставьте задачу';
                        } else {
                            return 'Заявка обработана';
                        }
                    }
                    return(
                    <RecordContextProvider key={request.id} value={request}>
                        <ListItem
                            button
                            component={Link}
                            to={`/requests/${request.id}`}
                             sx={{
                                borderLeft: request.status ? `4px solid ${getStatusColor(request.status)}` : null,
                                padding: '0 16px',
                                marginRight: '16px', 
                                border: isTaskTimeBeforeCurrentTime ? '4px solid red' : 'none',
                                animation: isTaskTimeBeforeCurrentTime ? 'blinkingBorder 1s infinite' : 'none',
                            }}
                        >
                            <Box display="flex" flex={1} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<Typography variant="body2">{`Дата создания: ${new Date(request.created_at).toLocaleString('ru-RU')}`}</Typography>}
                                secondary={request.updated_at ? `Дата обновления: ${new Date(request.updated_at).toLocaleString('ru-RU')}` : null}
                            />
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                //primary={`#${request.number}`}
                                secondary={<Box>
                                    {/*<Typography variant="body2" style={{ color: getStatusColor(request.status)}}>{request.status ? typeChoices.find(status => status.id === request.status).name : null}</Typography>*/}
                                    <Typography variant="body2">{formatTaskTime()}</Typography>
                                </Box>}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<Typography sx={{ 
                                    border: `1.5px solid ${props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.color}`,
                                    borderRadius: '0.5rem',
                                    padding: '0 0.5rem',
                                    width: 'fit-content'
                                }}>{props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.name || ''}</Typography>}
                                secondary={props.funnels.find((item) => item.id === request.funnel_id)?.name || ''}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={request.name}
                                secondary={request.manager_name}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<CallButton contact_phone={request.phone} type='link' confirmPopup={true} telegram_username={request.telegram_username} />}
                                secondary={<ReferenceField source="requestIncomeSource_id" reference="requestIncomeSources">
                                <TextField source="name" />
                            </ReferenceField>}
                            />
                            </Box>
                        </ListItem>
                    </RecordContextProvider>
                );
                })}
            </MUIList>}
        </>
    );
});

export default RequestList;
